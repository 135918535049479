import React, { useEffect, useRef, useState } from 'react';
import Message from './Message';
import { useChat } from '../../contexts/ChatContext';
import useDbChat from '../../helpers/database/useDbChat';
import useE2EE from '../../helpers/useE2EE';

export type Messages = {
  messageId: string;
  data: {
    date: Date;
    senderId: string;
    text: string;
    img?: string;
  };
};

export type EncryptedMessages = {
  messageId: string;
  encryptedMessage: string;
};

const Messages: React.FC = () => {
  const { decryptData } = useE2EE();
  const { getEncryptedMessages } = useDbChat();
  const [messages, setMessages] = useState<Messages[]>([]);
  const [encryptedMessages, setEncryptedMessages] = useState<EncryptedMessages[]>([]);
  const [displayedMessagesCount, setDisplayedMessagesCount] = useState<number>(20);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const {
    data: { chatId },
  } = useChat();
  const chatIdRef = useRef(chatId);

  useEffect(() => {
    //Referenz setzen, wenn der Chat wechselt
    chatIdRef.current = chatId;
    // Zustände zurücksetzen, wenn der Chat wechselt
    setMessages([]);
    setEncryptedMessages([]);
    setDisplayedMessagesCount(10);
    //OnSnapshot von den Chat-Nachrichten holen
    const unsubscribe = getEncryptedMessages(chatId, setEncryptedMessages);

    return () => {
      unsubscribe();
    };
  }, [chatId]);

  // Entschlüssle die Chat-Messages und speichere sie in einem neuen Hook
  useEffect(() => {
    const fetchData = async () => {
      try {
        const messagesArray: Messages[] = [];

        // Nur die angezeigten Nachrichten entschlüsseln, von den neuesten Nachrichten ausgehend
        const start = encryptedMessages.length - displayedMessagesCount;
        const end = encryptedMessages.length;
        const messagesToDecrypt = encryptedMessages.slice(Math.max(0, start), end);

        for (const d of messagesToDecrypt) {
          // Entschlüssle Chat-Message
          const decryptedMessages = await decryptData(d.encryptedMessage);

          // Füge die Chat-Message dem Array hinzu
          messagesArray.push({
            messageId: d.messageId,
            data: JSON.parse(decryptedMessages),
          });
        }
        if (chatIdRef.current === chatId) {
          setMessages(messagesArray);
        }
      } catch (error) {
        console.error('Chat messages could not be decrypted: ' + error);
      }
    };

    fetchData();
  }, [encryptedMessages]);

  //Mehr Nachrichten nachladen
  const loadMoreMessages = async () => {
    setIsLoadingMore(true);
    setDisplayedMessagesCount((prevCount) => prevCount + 10);

    // Nachrichten sofort nach dem Aktualisieren des displayedMessagesCount entschlüsseln und setzen
    try {
      const messagesArray: Messages[] = [];

      const start = encryptedMessages.length - (displayedMessagesCount + 5);
      const end = encryptedMessages.length;
      const messagesToDecrypt = encryptedMessages.slice(Math.max(0, start), end);

      for (const d of messagesToDecrypt) {
        const decryptedMessages = await decryptData(d.encryptedMessage);
        messagesArray.push({
          messageId: d.messageId,
          data: JSON.parse(decryptedMessages),
        });
      }
      if (chatIdRef.current === chatId) {
        setMessages(messagesArray);
      }
    } catch (error) {
      console.error('Chat messages could not be decrypted: ' + error);
    }
  };

  useEffect(() => {
    setMessages(messages);
  }, [messages]);

  return (
    <div className='messages'>
      {displayedMessagesCount < encryptedMessages.length && ( // Wenn es noch mehr Nachrichten gibt, die geladen werden können
        <button
          className='waves-effect waves-light btn-small btn-styled'
          onClick={loadMoreMessages}
        >
          Mehr Nachrichten laden
        </button>
      )}
      {messages.map((message) => (
        <Message
          message={message}
          key={message.messageId}
          isLoadingMore={isLoadingMore}
          setIsLoadingMore={setIsLoadingMore}
        />
      ))}
    </div>
  );
};

export default Messages;
