import React, { forwardRef } from 'react';

interface BlogProps {
  id: string;
  data: {
    title: string;
    content: string;
    colorScheme: string;
  };
  onClose: () => void;
}

const BlogModal = forwardRef<HTMLDivElement, BlogProps>((props, ref) => {
  return (
    <div ref={ref} className='modal modal-styled'>
      <div
        className='modal-title'
        style={{
          backgroundColor: props.data.colorScheme,
          color: props.data.colorScheme === '#ffd54f' ? '#262626' : '',
        }}
      >
        {props.data.title}
      </div>
      <div className='modal-content'>
        <div dangerouslySetInnerHTML={{ __html: props.data.content }} />
      </div>
      <div className='modal-footer'>
        <button className='waves-effect waves-green btn-flat' onClick={props.onClose}>
          Schliessen
        </button>
      </div>
    </div>
  );
});

export default BlogModal;
