import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { SecurityPlan } from '../../pages/emergency/CopingStrategyData';
import { Contact } from '../../pages/emergency/ContactData';
import useDbSecurityPlan from '../../helpers/database/useDbSecurityPlan';
import Icon from '@mdi/react';
import { mdilPencil } from '@mdi/light-js';

const CopingStrategyPlans: React.FC = () => {
  const [plans, setPlans] = useState<SecurityPlan[]>([]);
  const { getAllSecurityPlans } = useDbSecurityPlan();
  const { user } = useUser();
  const navigate = useNavigate();

  // Lade die Sicherheitspläne von der DB
  useEffect(() => {
    const fetchPlansAndContacts = async () => {
      if (user) {
        getAllSecurityPlans(user.uid, setPlans);
      }
    };
    fetchPlansAndContacts();
  }, [user]);

  // Leerstellen aus der Telefonnummer entfernen
  const cleanPhoneNumber = (number: string) => number.replace(/\s/g, '');

  // Kontakte bearbeiten
  const handleEditClick = (contact: Contact) => {
    navigate(`/emergency/contactdata?editingContact=${contact.contactId}`);
  };

  // Sicherheitsplan bearbeiten/löschen
  const handleEditPlan = (plan: SecurityPlan) => {
    navigate(`/emergency/copingstrategydata?editingPlan=${plan.planId}`);
  };

  // Type Guard für das contacts-Array
  function isContactArray(contacts: string[] | Contact[]): contacts is Contact[] {
    return !!contacts.length && typeof contacts[0] !== 'string';
  }

  return (
    <ul className='strategyplan-list'>
      {plans.map((plan) => (
        <li key={plan.planId} className='strategyplan-item'>
          <h1>
            {plan.data.title}
            <div className='options' onClick={() => handleEditPlan(plan)}>
              <Icon path={mdilPencil} size={1} />
            </div>
          </h1>
          {plan.data.warningSigns && (
            <div className='strategyplan-element'>
              <span className='bold'>Warnzeichen:</span>
              <div className='strategyplan-content'>{plan.data.warningSigns}</div>
            </div>
          )}
          {plan.data.calmingStrategies && (
            <div className='strategyplan-element'>
              <span className='bold'>Strategien:</span>
              <div className='strategyplan-content'>{plan.data.calmingStrategies}</div>
            </div>
          )}
          {plan.data.safePlace && (
            <div className='strategyplan-element'>
              <span className='bold'>Sicherer Ort:</span>
              <div className='strategyplan-content'>{plan.data.safePlace}</div>
            </div>
          )}
          {isContactArray(plan.data.contacts) && (
            <div>
              <h2 className='bold'>Kontaktieren:</h2>
              <ul className='emergency-list'>
                {plan.data.contacts.map((contact) => (
                  <li className='emergency-item' key={contact.contactId}>
                    <h2>
                      <span className='bold'>{contact.data.name}</span>
                      <span className='options' onClick={() => handleEditClick(contact)}>
                        <Icon path={mdilPencil} size={1} />
                      </span>
                    </h2>
                    {contact.data.relationship && <p>{contact.data.relationship}</p>}
                    {contact.data.address && <p>{contact.data.address}</p>}
                    {contact.data.phone1 && (
                      <a
                        href={`tel:${cleanPhoneNumber(contact.data.phone1)}`}
                        className='emergency-number'
                      >
                        {contact.data.phone1}
                      </a>
                    )}
                    {contact.data.phone2 && (
                      <a
                        href={`tel:${cleanPhoneNumber(contact.data.phone2)}`}
                        className='emergency-number'
                      >
                        {contact.data.phone2}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default CopingStrategyPlans;
