import React, { useEffect, useState } from 'react';
import noUiSlider, { PipsMode } from 'nouislider';
import 'nouislider/dist/nouislider.css';

interface SliderProps {
  id: string;
  label1: string;
  label2: string;
  onChange: (sliderId: string, value: number) => void;
  initValue: number;
}

const Slider: React.FC<SliderProps> = ({ id, label1, label2, onChange, initValue }) => {
  const [sliderValue, setSliderValue] = useState<number>(0);

  const filterPips = (value: number) => {
    if (value === -5 || value === 0 || value === 5) {
      return 1;
    }
    return 2;
  };

  useEffect(() => {
    setSliderValue(sliderValue);
  }, [sliderValue]);

  useEffect(() => {
    onChange(id, sliderValue);
  }, [sliderValue]);

  // Slider initialisieren
  useEffect(() => {
    const slider = document.getElementById(id);
    const node = document.createElement('div');
    if (slider) {
      const sliderUI = noUiSlider.create(slider, {
        range: {
          min: [-5],
          max: [5],
        },
        start: initValue,
        step: 1,
        pips: {
          mode: PipsMode.Steps,
          density: 10,
          filter: filterPips,
          format: {
            // 'to' zu dem formatierten Wert. Gibt einen Number Wert zurück
            to: function (value) {
              return Math.abs(value);
            },
          },
        },
      });

      node.classList.add('fake-fill');
      slider.appendChild(node);

      sliderUI.on('update', (values, handle, unencoded, tap, positions) => {
        const pos = positions[0];

        if (pos >= 50) {
          node.style.left = '50%';
          node.style.right = 'auto';
          node.style.width = `${pos - 50}%`;
        } else {
          node.style.left = 'auto';
          node.style.right = '50%';
          node.style.width = `${50 - pos}%`;
        }
      });

      sliderUI.on('set', (values) => {
        const newValue = parseInt(String(values[0]), 10);
        setSliderValue(newValue);
      });

      // Cleanup function
      return () => {
        sliderUI.destroy(); // Destroy the slider when the component unmounts
      };
    }
  }, [initValue]);

  return (
    <div className='mood-slider'>
      <div className='labels'>
        <span>{label1}</span>
        <span>{label2}</span>
      </div>
      <div id={id} style={{ width: '100%', height: '10px' }}></div>
    </div>
  );
};

export default Slider;
