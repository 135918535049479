import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  arrayUnion,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../useFirebase';
import { v4 as uuid } from 'uuid';
import { EncryptedUserChats } from '../../components/chat/Chats';
import { EncryptedMessages } from '../../components/chat/Messages';
import showToast from '../../components/Toast';
import useDbImage from './useDbImage';
import useE2EE from '../useE2EE';

type Message = {
  messageId: string;
  data: {
    text: string;
    senderId: string;
    date: Date;
    img?: string;
  };
};

type EncryptedMessage = {
  messageId: string;
  encryptedMessage: string;
};

const useDbChat = () => {
  const { encryptData, decryptData } = useE2EE();
  const navigate = useNavigate();
  const { deleteImageFromStorage } = useDbImage();

  // Erstelle für den Benutzer ein neues userChats-Dokument in der DB
  const createUserChat = async (uid: string) => {
    try {
      await setDoc(doc(db, 'userChats', uid), {});
    } catch (error) {
      console.error('User chat could not be created: ', error);
    }
  };

  // Aktualisiere userChats-Eintrag in der DB
  const updateUserChat = async (uid: string, chatId: string, text: string): Promise<void> => {
    try {
      const userChatsDoc = await getDoc(doc(db, 'userChats', uid));

      // Suche nach userChats Dokument für Benutzer
      if (userChatsDoc.exists()) {
        const userChatsData = userChatsDoc.data();

        // Überprüfung, ob für die ChatId verschlüsselte Daten vorhanden ist
        if (userChatsData[`${chatId}`] && userChatsData[`${chatId}`].encryptedUserChat) {
          // Entschlüssle UserChat
          const decryptedUserChat = await decryptData(userChatsData[`${chatId}`].encryptedUserChat);

          // Aktualisiere UserChat
          const updatedData = {
            ...JSON.parse(decryptedUserChat),
            timestamp: new Date(),
            lastMessage: text,
          };

          // Verschlüssle UserChat
          const encryptedUserChat = await encryptData(JSON.stringify(updatedData));

          await updateDoc(doc(db, 'userChats', uid), {
            [`${chatId}.encryptedUserChat`]: encryptedUserChat,
          });
        }
      }
    } catch (error) {
      console.error('User chat could not be updated: ', error);
    }
  };

  // Lade alle vorhandenen Userchats des Benutzers aus der DB
  // Gibt ein Array mit verschlüsselten Userchats-Daten zurück
  const getEncryptedUserChats = (
    uid: string,
    setEncryptedUserChats: Dispatch<SetStateAction<EncryptedUserChats[]>>,
  ) => {
    const unsubscribe = onSnapshot(doc(db, 'userChats', uid), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setEncryptedUserChats(data as EncryptedUserChats[]);
      } else {
        setEncryptedUserChats([]);
      }
    });

    return () => {
      unsubscribe();
    };
  };

  // Delete userChat and Chats
  const deleteUserChat = async (uid: string, chatId: string): Promise<void> => {
    try {
      // Überprüfe alle Nachrichten im Chat und lösche ggf. die Bilder
      const chatRef = doc(db, 'chats', chatId);
      const chatDoc = await getDoc(chatRef);
      if (chatDoc.exists() && chatDoc.data().messages) {
        for (const message of chatDoc.data().messages) {
          // Entschlüssle Chat-Nachrichten
          const decryptedMessage = await decryptData(message.encryptedMessage);
          const parsedMessage = JSON.parse(decryptedMessage);

          // Falls vorhanden, lösche Chat-Bilder von Storage
          if (parsedMessage.img) {
            await deleteImageFromStorage(parsedMessage.img);
          }
        }
      }

      // Lösche Chat-Einträge mit der übergebenen chatId
      const userChatRef = doc(db, 'userChats', uid);
      const userChatDoc = await getDoc(userChatRef);
      if (userChatDoc.exists()) {
        await updateDoc(userChatRef, {
          [`${chatId}`]: deleteField(),
        });
      }

      // Haben andere Benutzer auch diesen Chat?
      const otherUserId = chatId.replace(uid, ''); // Entferne die eigene uid aus der chatId
      const otherUserChatRef = doc(db, 'userChats', otherUserId);
      const otherUserChatDoc = await getDoc(otherUserChatRef);

      // Lösche Chat-Einträge mit der übergebenen chatId
      if (otherUserChatDoc.exists()) {
        await updateDoc(otherUserChatRef, {
          [`${chatId}`]: deleteField(),
        });
      }

      navigate('/home');
      showToast('Chat wurde gelöscht');
      await deleteDoc(chatRef);
    } catch (error) {
      console.error('User chat could not be deleted: ' + error);
      showToast('Fehler beim Löschen des Chats');
    }
  };

  // Sobald zwei Benutzer eine Unterhaltung starten, erstelle ein gemeinsames Chat-Dokument in der DB
  // Gibt die chatId zurück
  const createOrRetrieveChat = async (currentUserUid: string, selectedUserUid: string) => {
    const chatId =
      currentUserUid > selectedUserUid
        ? currentUserUid + selectedUserUid
        : selectedUserUid + currentUserUid;

    try {
      const chatDoc = await getDoc(doc(db, 'chats', chatId));

      if (!chatDoc.exists() && currentUserUid !== selectedUserUid) {
        // Erstelle einen leeren Bereich für den gemeinsamen Chat
        await setDoc(doc(db, 'chats', chatId), { messages: [] });

        // Verschlüssle die Datensätze für beide Benutzer
        const encryptedDataCurrentUser = await encryptData(
          JSON.stringify({
            timestamp: new Date(),
            userInfo: {
              uid: selectedUserUid,
            },
          }),
        );

        const encryptedDataSelectedUser = await encryptData(
          JSON.stringify({
            timestamp: new Date(),
            userInfo: {
              uid: currentUserUid,
            },
          }),
        );

        // Erstelle für beide Benutzer ihren User-Chat
        await updateDoc(doc(db, 'userChats', currentUserUid), {
          [`${chatId}.encryptedUserChat`]: encryptedDataCurrentUser,
        });
        await updateDoc(doc(db, 'userChats', selectedUserUid), {
          [`${chatId}.encryptedUserChat`]: encryptedDataSelectedUser,
        });
      }

      return chatId;
    } catch (error) {
      console.error('Chat could not be created or retrieved: ', error);
    }
  };

  // Ergänze die Chatnachrichten des Benutzers in der DB
  const updateChat = async (
    chatId: string,
    currentUser: { uid: string },
    text: string,
    imgURL?: string,
  ): Promise<void> => {
    const baseMessage: Message = {
      messageId: uuid(),
      data: {
        date: new Date(),
        senderId: currentUser.uid,
        text,
      },
    };

    try {
      if (imgURL) {
        baseMessage.data.img = imgURL;
      }

      // Verschlüssle die Chatdaten
      const encryptedMessage = await encryptData(JSON.stringify(baseMessage.data));

      await updateDoc(doc(db, 'chats', chatId), {
        messages: arrayUnion({
          messageId: baseMessage.messageId,
          encryptedMessage: encryptedMessage,
        }),
      });
    } catch (error) {
      console.error('Chat could not be updated: ', error);
    }
  };

  // Lade alle Nachrichten eines Chats anhand chatID aus der DB
  // Gibt ein Array mit verschlüsselten Mitteilungen zurück
  const getEncryptedMessages = (
    chatId: string,
    setEncryptedMessages: Dispatch<SetStateAction<EncryptedMessages[]>>,
  ) => {
    const unsubscribe = onSnapshot(doc(db, 'chats', chatId), (doc) => {
      if (doc.exists()) {
        const data = doc.data().messages;
        setEncryptedMessages(data as EncryptedMessages[]);
      } else {
        setEncryptedMessages([]);
      }
    });

    return unsubscribe;
  };

  // Aktualisieren von Nachrichten
  const editMessage = async (chatId: string, messageId: string, newText: string): Promise<void> => {
    try {
      const chatRef = doc(db, 'chats', chatId);
      const chatData = (await getDoc(chatRef)).data();
      if (!chatData || !chatData.messages) return;

      const updatedMessages: EncryptedMessage[] = chatData.messages.map(
        async (msg: EncryptedMessage) => {
          if (msg.messageId === messageId) {
            // Entschlüsseln der empfangenen Nachricht
            const decryptedMessage = await decryptData(msg.encryptedMessage);
            const parsedMessage = JSON.parse(decryptedMessage);

            // Hier wird der Text angepasst
            parsedMessage.text = newText;

            // Verschlüsseln der Nachricht
            const newMessage = JSON.stringify(parsedMessage);
            const encryptedMessage = await encryptData(newMessage);

            // Rückgabe der aktualisierten Nachricht
            return { ...msg, encryptedMessage: encryptedMessage };
          } else {
            // Für alle anderen Nachrichten wird keine Änderung vorgenommen
            return msg;
          }
        },
      );

      // Auf alle Promises der map-Funktion warten
      const updatedMessagesArray = await Promise.all(updatedMessages);

      await updateDoc(chatRef, { messages: updatedMessagesArray });
    } catch (error) {
      console.error('Chat message could not be edited: ' + error);
    }
  };

  // Löschen von Nachrichten
  const deleteMessage = async (
    chatId: string,
    messageId: string,
  ): Promise<EncryptedMessage[] | null> => {
    try {
      const chatRef = doc(db, 'chats', chatId);
      const chatData = (await getDoc(chatRef)).data();
      if (!chatData || !chatData.messages) return null;

      const message = chatData.messages.find((msg: Message) => msg.messageId === messageId);

      const decryptedMessage = await decryptData(message.encryptedMessage);
      const parsedMessage = JSON.parse(decryptedMessage);

      if (parsedMessage?.img) {
        await deleteImageFromStorage(parsedMessage.img);
      }

      const filteredMessages: EncryptedMessage[] = chatData.messages.filter(
        (msg: EncryptedMessage) => msg.messageId !== messageId,
      );

      await updateDoc(chatRef, { messages: filteredMessages });

      return filteredMessages; // Rückgabe des aktualisierten Nachrichtenarrays
    } catch (error) {
      console.error('Chat message could not be deleted: ' + error);
      return null;
    }
  };

  return {
    createUserChat,
    updateUserChat,
    getEncryptedUserChats,
    deleteUserChat,
    createOrRetrieveChat,
    updateChat,
    getEncryptedMessages,
    editMessage,
    deleteMessage,
  };
};

export default useDbChat;
