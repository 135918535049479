import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import { Link, useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const [pageTitle, setPageTitle] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setPageTitle('Startseite');
        break;
      case '/journal':
        setPageTitle('Tagebuch');
        break;
      case '/chat':
        setPageTitle('Chat');
        break;
      case '/info':
        setPageTitle('Information');
        break;
      case '/emergency':
        setPageTitle('Notfall');
        break;
    }
  }, [location]);

  useEffect(() => {
    // Initialisiere die Sidebar
    const sideNavElement = document.querySelector('.sidenav');
    if (sideNavElement) {
      M.Sidenav.init(sideNavElement, {
        edge: 'left',
        draggable: false,
      });
    }
  }, []);

  return (
    <nav className='header'>
      <Link to='#' data-target='slide-out' className='sidenav-trigger valign-wrapper'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='icon'>
          <defs>
            <linearGradient id='grad3' x1='0%' y1='0%' x2='100%' y2='100%'>
              <stop offset='0%' stopColor='#f06292' />
              <stop offset='100%' stopColor='#f9a76a' />
            </linearGradient>
          </defs>
          <path d='M3,8V7H20V8H3M20,12V13H3V12H20M3,17H20V18H3V17Z' fill='url(#grad3)' />
        </svg>
      </Link>
      <title className='pagetitle'>{pageTitle}</title>
      <ul id='slide-out' className='sidenav styled-nav'>
        <Sidebar />
      </ul>
    </nav>
  );
};

export default Header;
