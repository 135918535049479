import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import useDbJournal from './database/useDbJournal';
import showToast from '../components/Toast';

type SliderData = {
  slider1: number;
  slider2: number;
  slider3: number;
  slider4: number;
  slider5: number;
  slider6: number;
  slider7: number;
  slider8: number;
};

export type JournalData = {
  sliderData: SliderData;
  motto: string;
  goals: string;
  achievements: string;
  summary: string;
};

export type Feelings = {
  [key: string]: number;
};

const useJournal = () => {
  const { getDayplan, getReview, getAllDayplans, getAllReviews } = useDbJournal();
  const { user: currentUser } = useContext(UserContext);
  const location = useLocation();
  const editingDate = new URLSearchParams(location.search).get('editingDate');
  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0)); // Setzt Timestamp auf Mitternacht
  const initialDate = editingDate
    ? new Date(new Date(editingDate).setHours(0, 0, 0, 0))
    : todayMidnight;
  const initialJournalData = {
    sliderData: {
      slider1: 0,
      slider2: 0,
      slider3: 0,
      slider4: 0,
      slider5: 0,
      slider6: 0,
      slider7: 0,
      slider8: 0,
    },
    motto: '',
    goals: '',
    achievements: '',
    summary: '',
  };
  const [date, setDate] = useState<Date>(initialDate);
  const [journalData, setJournalData] = useState<JournalData>(initialJournalData);
  const [existing, setExisting] = useState<boolean>(false);
  const isToastBreak = useRef(false);

  // Bei Datumänderung werden Daten aus DB gesucht
  useEffect(() => {
    switch (location.pathname) {
      case '/journal/dayplan':
        loadDayplanData();
        break;
      case '/journal/review':
        loadReviewData();
        break;
    }
  }, [date]);

  // Workaround, damit gleicher Toast nicht 2x direkt nacheinander angezeigt wird
  useEffect(() => {
    setTimeout(() => {
      isToastBreak.current = false;
    }, 500);
  }, [isToastBreak.current]);

  // Falls vorhanden, bestehende Dayplan-Daten laden. Ansonsten Default-Werte setzen.
  const loadDayplanData = async () => {
    if (currentUser) {
      const entry = await getDayplan(currentUser.uid, date);
      if (entry) {
        setExisting(true);
        setJournalData(JSON.parse(entry));
        if (!isToastBreak.current) {
          isToastBreak.current = true;
          showToast('Tagesplan vorhanden');
        }
      } else {
        setExisting(false);
        setJournalData(initialJournalData);
      }
    }
  };

  // Falls vorhanden, bestehende Review-Daten laden. Ansonsten Default-Werte setzen.
  const loadReviewData = async () => {
    if (currentUser) {
      const entry = await getReview(currentUser.uid, date);
      if (entry) {
        setExisting(true);
        setJournalData(JSON.parse(entry));
        if (!isToastBreak.current) {
          isToastBreak.current = true;
          showToast('Rückblick vorhanden');
        }
      } else {
        setExisting(false);
        setJournalData(initialJournalData);
      }
    }
  };

  // Durchschnittswerte von Dayplan
  const getAverageFeelingsDayplan = async (): Promise<SliderData> => {
    try {
      if (currentUser) {
        const dataArray = await getAllDayplans(currentUser.uid);
        const average = await calculateAverageFeelings(dataArray);
        return average;
      } else {
        return initialJournalData.sliderData;
      }
    } catch (error) {
      console.error('Average feeling data for review could not be loaded: ' + error);
      return initialJournalData.sliderData;
    }
  };

  // Durchschnittswerte von Review
  const getAverageFeelingsReview = async (): Promise<SliderData> => {
    try {
      if (currentUser) {
        const dataArray = await getAllReviews(currentUser.uid);
        const average = await calculateAverageFeelings(dataArray);
        return average;
      } else {
        return initialJournalData.sliderData;
      }
    } catch (error) {
      console.error('Average feeling data for review could not be loaded: ' + error);
      return initialJournalData.sliderData;
    }
  };

  const calculateAverageFeelings = (dataArray: JournalData[]) => {
    // Initialisiere ein Objekt, um die Summen der Werte zu speichern.
    const sum: SliderData = {
      slider1: 0,
      slider2: 0,
      slider3: 0,
      slider4: 0,
      slider5: 0,
      slider6: 0,
      slider7: 0,
      slider8: 0,
    };

    // Iteriere durch das ursprüngliche Array und berechne die Summen.
    dataArray.forEach((data) => {
      sum.slider1 += data.sliderData.slider1;
      sum.slider2 += data.sliderData.slider2;
      sum.slider3 += data.sliderData.slider3;
      sum.slider4 += data.sliderData.slider4;
      sum.slider5 += data.sliderData.slider5;
      sum.slider6 += data.sliderData.slider6;
      sum.slider7 += data.sliderData.slider7;
      sum.slider8 += data.sliderData.slider8;
    });

    // Berechne die Durchschnittswerte, indem du die Summen durch die Anzahl der Elemente im Array teilst.
    const numEntries = dataArray.length;
    const average: SliderData = {
      slider1: sum.slider1 / numEntries,
      slider2: sum.slider2 / numEntries,
      slider3: sum.slider3 / numEntries,
      slider4: sum.slider4 / numEntries,
      slider5: sum.slider5 / numEntries,
      slider6: sum.slider6 / numEntries,
      slider7: sum.slider7 / numEntries,
      slider8: sum.slider8 / numEntries,
    };

    return average;
  };

  // Ermittelt anhand SliderData, welches Gefühl welche Werte hat
  // Gibt ein Feelings-Array zurück
  const constructFeelingArray = (feelings: Feelings) => {
    const labels = [
      'Freude',
      'Liebe',
      'Hoffnung',
      'Geborgenheit',
      'Aufgewecktheit',
      'Interessiertsein',
      'Entspanntheit',
      'Mut',
      'Trauer',
      'Hass',
      'Verzweiflung',
      'Einsamkeit',
      'Müdigkeit',
      'Antriebslosigkeit',
      'Stress',
      'Angst',
    ];

    const result: Feelings = {};

    labels.forEach((label) => {
      result[label] = 0;
    });

    for (const feeling in feelings) {
      if (Object.prototype.hasOwnProperty.call(feelings, feeling)) {
        const value = feelings[feeling];
        const index = parseInt(feeling.substring(6)) - 1;

        if (value < 0) {
          result[labels[index]] = Math.abs(value);
          result[labels[index + 8]] = 0;
        } else if (value > 0) {
          result[labels[index]] = 0;
          result[labels[index + 8]] = value;
        }
      }
    }
    return result;
  };

  // Vergleicht die übergebenen Arrays und filtert alle Werte heraus, die in allen Arrays 0 sind
  // Gibt die gefilterten Arrays zurück
  const filterFeelingArrays = async (dayplanArray: Feelings, reviewArray: Feelings) => {
    const filteredDayplanArray: Feelings = {};
    const filteredReviewArray: Feelings = {};

    for (const key in dayplanArray) {
      if (dayplanArray[key] !== 0 || reviewArray[key] !== 0) {
        filteredDayplanArray[key] = dayplanArray[key];
        filteredReviewArray[key] = reviewArray[key];
      }
    }

    return { filteredDayplanArray, filteredReviewArray };
  };

  return {
    date,
    journalData,
    existing,
    setDate,
    setJournalData,
    getAverageFeelingsDayplan,
    getAverageFeelingsReview,
    constructFeelingArray,
    filterFeelingArrays,
  };
};

export default useJournal;
