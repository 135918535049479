import React, { useRef, useState } from 'react';
import Messages from './Messages';
import Input from './Input';
import { useChat } from '../../contexts/ChatContext';
import useDbChat from '../../helpers/database/useDbChat';
import Icon from '@mdi/react';
import { mdilDelete } from '@mdi/light-js';
import ChatDeleteModal from '../modals/ChatDeleteModal';

const Chat: React.FC = () => {
  const [modalInstance, setModalInstance] = useState<M.Modal | null>(null);
  const {
    data: { user, chatId },
  } = useChat();
  const { deleteUserChat } = useDbChat();
  const { dispatch } = useChat();
  const myUid = chatId.replace(user.uid, '');

  // Modal Initialisierung
  const modalRef = useRef<HTMLDivElement>(null);

  // Modal öffnen
  const onModalOpen = () => {
    if (modalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(modalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };

  // Modal schliessen
  const onModalConfirm = async () => {
    await deleteUserChat(myUid, chatId);
    dispatch({ type: 'RESET_USER' });

    if (modalInstance) {
      modalInstance.close();
    }
  };

  const onModalCancel = () => {
    if (modalInstance) {
      modalInstance.close();
    }
  };

  return (
    <div className='chat'>
      <div className='chatInfo'>
        <span>{user.displayName}</span>
        <div className='chatIcon' data-target='deleteModal'>
          <a onClick={onModalOpen}>
            <Icon path={mdilDelete} size={1} />
          </a>
        </div>
      </div>
      <Messages />
      <Input />
      <ChatDeleteModal
        id='chatDeleteModal'
        ref={modalRef}
        onConfirm={onModalConfirm}
        onCancel={onModalCancel}
      />
    </div>
  );
};

export default Chat;
