import React, { useEffect, useRef, useState } from 'react';
import useDbMusic, { MusicItem } from '../../helpers/database/useDbMusic';

const Relaxation: React.FC = () => {
  const { getAllMusic } = useDbMusic();
  const [radius, setRadius] = useState(50);
  const [text, setText] = useState('');
  const [selectedTrackURL, setSelectedTrackURL] = useState('');
  const [musicList, setMusicList] = useState<MusicItem[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMusicLoaded, setIsMusicLoaded] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Alle MusicItems aus der DB laden
  useEffect(() => {
    const fetchData = async () => {
      try {
        const musicData = await getAllMusic();
        setMusicList(musicData);

        // Setze den ersten Track als Standard
        if (musicData.length > 0) {
          setSelectedTrackURL(musicData[0].url);
        }
      } catch (error) {
        console.error('Music could not be retrieved: ' + error);
      }
    };
    fetchData();
  }, []);

  // Initialisiere das Dropdown-Menü von Materialize sobald die Kontakte geladen wurden
  useEffect(() => {
    initDropDown();
  }, [musicList]);

  const initDropDown = () => {
    const elem = document.querySelectorAll('select');
    if (elem) {
      window.M.FormSelect.init(elem);
    }
  };

  const handleMusicSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    stopExercise(event);
    setSelectedTrackURL(event.target.value);
    setIsMusicLoaded(false);
  };

  const startExercise = (event: React.FormEvent) => {
    event.preventDefault();
    if (!isPlaying) {
      setText('Übung startet...');
    }

    setIsPlaying(true);
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const stopExercise = (event?: React.FormEvent) => {
    event?.preventDefault();
    setIsPlaying(false);
    setRadius(50);

    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let interval: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any;

    if (isPlaying) {
      const inhale = () => {
        setRadius(100);
        setText('Einatmen');
      };
      const exhale = () => {
        setRadius(50);
        setText('Ausatmen');
      };

      interval = setInterval(() => {
        inhale();
        timeout = setTimeout(exhale, 4000);
      }, 8000);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [isPlaying]);

  return (
    <div className='page relaxation'>
      <h1>Entspannung</h1>
      <p>Geleitete Atemübung zur Entspannung.</p>
      <div className='circle-box'>
        <div className='circle' style={{ width: `${radius * 2}px`, height: `${radius * 2}px` }}>
          <span>{text}</span>
        </div>
      </div>

      <div>
        <form className='form-styled' onSubmit={startExercise}>
          <label htmlFor='music'>Musik auswählen:</label>
          <select onChange={handleMusicSelection}>
            {musicList.map((musicItem) => {
              return (
                <option key={musicItem.url} value={musicItem.url}>
                  {musicItem.title} by {musicItem.artist}
                </option>
              );
            })}
          </select>
          <div className='audio'>
            <audio
              controls
              ref={audioRef}
              src={selectedTrackURL}
              onEnded={() => {
                stopExercise();
              }}
              onPause={() => {
                stopExercise();
                setText('Übung stoppt...');
              }}
              onPlay={() => {
                setIsPlaying(true), setText('Übung startet...');
              }}
              onCanPlay={() => setIsMusicLoaded(true)}
            ></audio>
          </div>
          <div className='btn-container'>
            <div className='btn-group'>
              <button
                type='submit'
                className='btn-large btn-styled waves-effect waves-light'
                autoFocus={true}
                disabled={!isMusicLoaded}
              >
                Starten
              </button>
              <button
                type='button'
                className='btn-large btn-styled waves-effect waves-light'
                onClick={stopExercise}
                disabled={!isMusicLoaded}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Relaxation;
