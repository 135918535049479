import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../helpers/useFirebase';
import useAuth from '../helpers/useAuth';

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const { removeCookie } = useAuth();

  const handleLogout = async () => {
    // Versuche, Benutzer bei Firebase abzumelden
    try {
      closeSidebar();

      await signOut(auth);
      removeCookie('lastVisitedPath');
      removeCookie('isAuthenticated');
      navigate('/login');
    } catch {
      console.error('Logout failed');
    }
  };

  // Sidebar schliessen
  const closeSidebar = () => {
    const sideNavElement = document.querySelector('.sidenav');
    if (sideNavElement) {
      const instance = M.Sidenav.getInstance(sideNavElement);
      instance.close();
    }
  };

  return (
    <div>
      <button className='btn-large btn-styled waves-effect waves-light' onClick={handleLogout}>
        Abmelden
      </button>
    </div>
  );
};

export default Logout;
