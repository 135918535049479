import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from '../contexts/UserContext';
import useDbUser from './database/useDbUser';

const useAuth = () => {
  const { isDisplayNameUnique } = useDbUser();
  const [cookies, setCookie, removeCookie] = useCookies([
    'isAuthenticated',
    'lastVisitedPath',
    'cookiesAllowed',
  ]);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>(' ');
  const [displayNameError, setDisplayNameError] = useState<string>('');
  const [email, setEmail] = useState<string>('@');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>(' ');
  const [passwordError, setPasswordError] = useState<string>('');
  const [avatar, setAvatar] = useState<File | undefined>();
  const [avatarError, setAvatarError] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { user: currentUser } = useContext(UserContext);

  // Cookie überprüfen und entsprechend den Authentifizierungsstatus setzen
  useEffect(() => {
    if (cookies.isAuthenticated) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [cookies]);

  // Anzeigename überprüfen, ob nicht leer und noch nicht verwendet
  const validateDisplayName = async () => {
    if (displayName === ' ') {
      // Hack, damit nicht beim 1. Laden bereits Fehlermeldung angezeigt wird
      setDisplayNameError('');
      // Anzeigename muss mind. 3 Zeichen lang sein
    } else if (displayName.length < 3) {
      setDisplayNameError('Anzeigename muss mind. 3 Zeichen lang sein.');
      // Überprüfen, ob Anzeigename noch nicht verwendet wird und nicht dem aktuellen Namen entspricht
    } else if (
      (await isDisplayNameUnique(displayName)) == false &&
      displayName !== currentUser?.displayName
    ) {
      setDisplayNameError('Anzeigename wird bereits verwendet.');
    } else {
      setDisplayNameError('');
    }
  };

  useEffect(() => {
    validateDisplayName();
  }, [displayName]);

  // E-Mail überprüfen, ob nicht leer und @ enthalten
  const validateEmail = () => {
    if (email === '') {
      setEmailError('E-Mail-Adresse darf nicht leer sein.');
    } else if (!email.includes('@')) {
      setEmailError('E-Mail-Adresse muss ein @ enthalten.');
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    validateEmail();
  }, [email]);

  // Passwort überprüfen, ob mind. 12 Zeichen, 1 Gross-/Kleinbuchstabe, 1 Zahl und 1 Sonderzeichen vorkommen
  const validatePassword = () => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialCharacter = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);

    if (password === ' ' || password === '') {
      setPasswordError('(mind. 12 Zeichen mit Gross-/Kleinbuchstaben, Zahl und Sonderzeichen)');
    } else if (password.length < 12) {
      setPasswordError('Passwort muss mind. 12 Zeichen lang sein.');
    } else if (!hasUpperCase) {
      setPasswordError('Passwort muss mind. einen Grossbuchstaben enthalten.');
    } else if (!hasLowerCase) {
      setPasswordError('Passwort muss mind. einen Kleinbuchstaben enthalten.');
    } else if (!hasNumber) {
      setPasswordError('Passwort muss mind. eine Zahl enthalten.');
    } else if (!hasSpecialCharacter) {
      setPasswordError('Passwort muss mind. ein Sonderzeichen enthalten.');
    } else {
      setPasswordError('');
    }
  };

  useEffect(() => {
    validatePassword();
  }, [password]);

  // Avatar überprüfen, ob nicht zu gross und unterstütztes Bildformat
  const validateAvatar = () => {
    if (avatar && !isImage(avatar)) {
      setAvatarError('Ausgewählte Datei hat kein gültiges Bildformat.');
    } else if (avatar && avatar.size > 3000000) {
      setAvatarError('Avatar darf max. 3 MB gross sein.');
    } else {
      setAvatarError('');
    }
  };

  // Validate Picture in Asynchronously Contexts
  const validatePictureDirect = async (img: File): Promise<string> => {
    return new Promise<string>((resolve) => {
      if (img && !isImage(img)) {
        resolve('Ausgewählte Datei hat kein gültiges Bildformat.');
      } else if (img && img.size > 3000000) {
        resolve('Bild darf max. 3 MB gross sein.');
      } else {
        resolve('');
      }
    });
  };

  // Bildformat überprüfen
  const isImage = (avatar: File): boolean => {
    const supportedImageTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/webp',
      'image/svg+xml',
    ];

    return supportedImageTypes.includes(avatar.type);
  };

  useEffect(() => {
    validateAvatar();
  }, [avatar]);

  const isError = () => {
    return (
      displayName === ' ' ||
      email === '@' ||
      password === ' ' ||
      displayNameError ||
      emailError ||
      passwordError ||
      avatarError
    );
  };

  return {
    cookies,
    isAuthenticated,
    displayName,
    displayNameError,
    email,
    emailError,
    password,
    passwordError,
    avatar,
    avatarError,
    error,
    setCookie,
    removeCookie,
    setAuthenticated,
    setDisplayName,
    setEmail,
    setPassword,
    setAvatar,
    setError,
    isError,
    validatePictureDirect,
  };
};

export default useAuth;
