import React, { forwardRef, useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import showToast from '../Toast';

interface PasswordResetProps {
  id: string;
  onClose: () => void;
}

const PasswordResetModal = forwardRef<HTMLDivElement, PasswordResetProps>((props, ref) => {
  const auth = getAuth();
  const [email, setEmail] = useState<string>('');

  const onSend = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      showToast('Passwortrücksetzungsmail gesendet');
    } catch (error) {
      console.error('Password reset mail could not be send: ' + error);
      showToast('Fehler beim Senden des Passwortrücksetzungsmails');
    }
  };

  return (
    <div ref={ref} className='modal modal-styled reset-modal modal-small'>
      <div className='modal-title'>Passwort zurücksetzen</div>
      <div className='modal-content'>
        <p>
          Gebe unten deine E-Mailadresse ein und klicke auf &quot;Passwort zurücksetzen&quot;. Falls
          die Adresse bei MeaVia registriert ist, erhältst du anschliessend eine E-Mail zum
          Zurücksetzen deines Passworts.
        </p>
        <form className='form-styled'>
          <div className='input-field'>
            <input
              id='reset-email'
              type='text'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor='reset-email'>E-Mailadresse *</label>
          </div>
        </form>
      </div>
      <div className='modal-footer'>
        <button className='waves-effect waves-red btn-flat' onClick={props.onClose}>
          Schliessen
        </button>
        <button className='waves-effect waves-green btn-flat' onClick={onSend}>
          Passwort zurücksetzen
        </button>
      </div>
    </div>
  );
});

export default PasswordResetModal;
