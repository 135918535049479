import React, { forwardRef, useEffect, useState } from 'react';
import showToast from '../Toast';
import { User, sendEmailVerification } from 'firebase/auth';

interface VerificationProps {
  id: string;
  isFirstCall: boolean;
  user: User | undefined;
  onClose: () => void;
}

const VerificationModal = forwardRef<HTMLDivElement, VerificationProps>((props, ref) => {
  const [showButton, setShowButton] = useState<boolean>(true);
  const onSend = async () => {
    setShowButton(false);
    try {
      if (props.user) {
        await sendEmailVerification(props.user);
        showToast('Bestätigungsmail gesendet.');
      }
    } catch (error) {
      showToast('Zu viele Versuche.');
      showToast('Versuche es später nochmals.');
      console.error('Verification mail could not be send: ' + error);
    }
  };

  // 2 Minuten Button blockieren, um Too-many-requests zu verhindern
  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 120000);
  }, [showButton]);

  return (
    <div ref={ref} className='modal modal-styled modal-small'>
      <div className='modal-title'>Bestätigung E-Mailadresse</div>
      <div className='modal-content'>
        {props.isFirstCall ? (
          <p>
            Du hast eine E-Mail zur Bestätigung deiner Adresse erhalten. Bitte schau in deinem
            Posteingang nach und folge den Anweisungen, um deine E-Mail-Adresse zu bestätigen.
          </p>
        ) : (
          <p>
            Die E-Mail-Bestätigung steht noch aus. Bitte überprüfe deinen Posteingang und folge den
            Anweisungen in der E-Mail, um deine Adresse zu bestätigen.
          </p>
        )}
      </div>
      <div className='modal-footer'>
        {!props.isFirstCall && (
          <button
            className={`waves-effect waves-green btn-flat ${showButton ? '' : 'disabled'}`}
            onClick={onSend}
          >
            Mail nochmals senden
          </button>
        )}
        <button className='waves-effect waves-green btn-flat' onClick={props.onClose}>
          Schliessen
        </button>
      </div>
    </div>
  );
});

export default VerificationModal;
