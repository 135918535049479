import React, { forwardRef } from 'react';

interface CookieProps {
  id: string;
  onClose: () => void;
  onAccept: () => void;
}

const CookieModal = forwardRef<HTMLDivElement, CookieProps>((props, ref) => {
  return (
    <div ref={ref} className='modal modal-styled modal-small'>
      <div className='modal-title'>Cookies</div>
      <div className='modal-content'>
        <p>
          Wir verwenden Cookies, um die Benutzerfreundlichkeit unserer App zu verbessern.
          <br />
          Es werden keine persönlichen Daten erfasst. <br />
          <br />
          Bist du damit einverstanden?
        </p>
      </div>
      <div className='modal-footer'>
        <button className='waves-effect waves-red btn-flat' onClick={props.onClose}>
          Notwendige Cookies erlauben
        </button>
        <button className='waves-effect waves-green btn-flat' onClick={props.onAccept}>
          Alle Cookies erlauben
        </button>
      </div>
    </div>
  );
});

export default CookieModal;
