import React, { FormEvent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Datepicker from '../../components/journal/Datepicker';
import SliderContainer from '../../components/journal/SliderContainer';
import showToast from '../../components/Toast';
import useJournal from '../../helpers/useJournal';
import useDbJournal from '../../helpers/database/useDbJournal';
import { UserContext } from '../../contexts/UserContext';

const Review = () => {
  const navigate = useNavigate();
  const { updateReview, deleteReview } = useDbJournal();
  const { date, journalData, existing, setDate, setJournalData } = useJournal();
  const { user: currentUser } = useContext(UserContext);

  // Textareas nach Datenladen neu rendern, sodass Höhe entsprechend dem Inhalt ist
  useEffect(() => {
    const textareaAchievements = document.getElementById('achievements');
    textareaAchievements && M.textareaAutoResize(textareaAchievements);
    const textareaSummary = document.getElementById('summary');
    textareaSummary && M.textareaAutoResize(textareaSummary);
  }, [journalData]);

  // Datepicker Datumswechsel
  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
  };

  // Slider Änderung Value
  const handleSliderChange = (sliderId: string, value: number) => {
    setJournalData({
      ...journalData,
      sliderData: {
        ...journalData.sliderData,
        [sliderId]: value,
      },
    });
  };

  // Textfeld Änderung Value
  const handleTextareaChange = (e: { target: { value: string } }, field: string) => {
    setJournalData({ ...journalData, [field]: e.target.value });
  };

  // Lösche Rückblick
  const handleDelete = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (currentUser) {
        await deleteReview(currentUser.uid, date);
        showToast('Eintrag gelöscht');
        navigate('/journal');
      }
    } catch (error) {
      console.error('Error deleting review: ' + error);
    }
  };

  // Speichere Review
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (currentUser) {
        await updateReview(currentUser.uid, date, JSON.stringify(journalData));
        showToast('Rückblick gespeichert');
        navigate('/journal');
      }
    } catch (error) {
      console.error('Review could not be saved: ' + error);
    }
  };

  return (
    <div className='page review'>
      <form className='form-styled' onSubmit={handleSubmit}>
        <h1>Rückblick erfassen</h1>
        <div className='flex-group-1'>
          <Datepicker onChange={handleDateChange} initValue={date} />
          <SliderContainer onChange={handleSliderChange} initValues={journalData} />
          <div className='input-field'>
            <textarea
              id='achievements'
              className='materialize-textarea'
              value={journalData.achievements}
              onChange={(e) => handleTextareaChange(e, 'achievements')}
            ></textarea>
            <label htmlFor='achievements' className={`${journalData.achievements ? 'active' : ''}`}>
              Was hast du heute erreicht?
            </label>
          </div>
          <div className='input-field'>
            <textarea
              id='summary'
              className='materialize-textarea'
              value={journalData.summary}
              onChange={(e) => handleTextareaChange(e, 'summary')}
            ></textarea>
            <label htmlFor='summary' className={`${journalData.summary ? 'active' : ''}`}>
              Fasse hier deinen Tag zusammen
            </label>
          </div>
        </div>
        <div className='btn-container'>
          <div className='btn-group'>
            <button
              className='btn-large btn-styled waves-effect waves-light'
              onClick={handleSubmit}
            >
              {existing ? 'Aktualisieren' : 'Speichern'}
            </button>
            <button
              className='btn-large btn-styled waves-effect waves-light'
              onClick={() => navigate('/journal')}
            >
              Abbrechen
            </button>
            {existing && (
              <button
                className='btn-large btn-styled waves-effect waves-light btn-red'
                onClick={handleDelete}
              >
                Rückblick löschen
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Review;
