import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../components/Card';
import IconCall from '../../assets/Emergency_Call.svg';
import IconStrategy from '../../assets/Emergency_Strategy.svg';
import IconIsland from '../../assets/Emergency_Island.svg';

const Emergency = () => {
  return (
    <div className='page emergency'>
      <div className='flex-group'>
        <Link to='/emergency/emergencydata'>
          <Card
            image={IconCall}
            imageAlternative='Anruf'
            title='Notfallkontakte'
            leadtext='Nehme mit jemandem Kontakt auf.'
          />
        </Link>
        <Link to='/emergency/copingstrategyplan'>
          <Card
            image={IconStrategy}
            imageAlternative='Strategie'
            title='Bewältigungsstrategien'
            leadtext='Erfasse oder lese deine Bewältigungsstrategien.'
          />
        </Link>
        <Link to='/emergency/relaxation'>
          <Card
            image={IconIsland}
            imageAlternative='Insel'
            title='Entspannung'
            leadtext='Gönne dir mit Musik und Atemübung etwas Ruhe.'
          />
        </Link>
      </div>
    </div>
  );
};

export default Emergency;
