import { deleteField, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { SecurityPlan } from '../../pages/emergency/CopingStrategyData';
import { db } from '../useFirebase';
import { Contact } from '../../pages/emergency/ContactData';
import useDbContact from './useDbContact';
import { Dispatch, SetStateAction } from 'react';
import useE2EE from '../useE2EE';

type EncryptedSecurityPlan = {
  encryptedSecurityPlan: string;
};

const useDbSecurityPlan = () => {
  const { encryptData, decryptData } = useE2EE();
  const { getContact } = useDbContact();

  // Erstelle Sicherheitsplan für Benutzer in DB
  const createOrAddSecurityPlan = async (
    uid: string,
    planId: string,
    securityPlan: SecurityPlan,
  ) => {
    try {
      const securityPlanRef = doc(db, 'securityPlans', uid);
      const securityPlanDoc = await getDoc(securityPlanRef);

      // Überprüfung, ob Sicherheitsplan vorhanden ist
      if (!securityPlanDoc.exists()) {
        // Falls nicht, einen erstellen
        await setDoc(securityPlanRef, {});
      }

      // Verschlüssle den Sicherheitsplan
      const encryptedSecurityPlan = await encryptData(JSON.stringify(securityPlan));

      // Erstelle Sicherheitsplan mit der ID als Schlüssel
      await updateDoc(securityPlanRef, {
        [`${planId}`]: {
          encryptedSecurityPlan: encryptedSecurityPlan,
        },
      });
    } catch (error) {
      console.error('Securityplan could not be created: ', error);
    }
  };

  // Hole Sicherheitsplan mit entsprechender PlanId aus der DB
  // Gibt Sicherheitsplan als Objekt zurück
  const getSecurityPlan = async (uid: string, planId: string): Promise<SecurityPlan | null> => {
    try {
      const securityPlanDoc = await getDoc(doc(db, 'securityPlans', uid));

      // Überprüfung, ob Sicherheitsplan vorhanden ist
      if (securityPlanDoc.exists()) {
        const securityPlanData = securityPlanDoc.data();

        // Überprüfung, ob für ausgewählten Tag ein SecurityPlan vorhanden ist
        if (securityPlanData[planId]) {
          // Entschlüssle Security Plan
          const decryptedSecurityPlan = await decryptData(
            securityPlanData[planId].encryptedSecurityPlan,
          );
          return JSON.parse(decryptedSecurityPlan);
        }
      }
      return null;
    } catch (error) {
      console.error('Securityplan could not be fetched: ' + error);
      return null;
    }
  };

  // Hole alle Sicherheitspläne vom Benutzer aus der DB
  // Gibt ein Array von allen Sicherheitsplänen zurück
  const getAllSecurityPlans = async (
    uid: string,
    setPlans: Dispatch<SetStateAction<SecurityPlan[]>>,
  ) => {
    try {
      const securityPlanDoc = await getDoc(doc(db, 'securityPlans', uid));

      // Überprüfung, ob Sicherheitsplan vorhanden ist
      if (securityPlanDoc.exists()) {
        const data = securityPlanDoc.data();
        const decryptedPlans: SecurityPlan[] = [];

        if (data) {
          const encryptedPlans: EncryptedSecurityPlan[] = Object.values(
            data,
          ) as EncryptedSecurityPlan[];

          for (const encryptedPlan of encryptedPlans) {
            // Entschlüssle Sicherheitsplan
            const decryptedPlan = await decryptData(encryptedPlan.encryptedSecurityPlan);
            decryptedPlans.push(JSON.parse(decryptedPlan));
          }

          // ID der Kontakte durchgehen und die Kontakte aus der DB holen
          for (const plan of decryptedPlans) {
            const contactsData: Contact[] = [];

            for (const contactId of plan.data.contacts) {
              const contact = await getContact(uid, contactId as string);
              if (contact) {
                contactsData.push(contact);
              }
            }
            // Überschreiben des contacts-Array im Plan mit den abgerufenen Kontakten
            plan.data.contacts = contactsData;
          }

          setPlans(decryptedPlans);
        }
      }
    } catch (error) {
      console.error('Securityplans could not be fetched: ' + error);
    }
  };

  // Aktualisiere Sicherheitsplan
  const updateSecurityPlan = async (securityPlan: SecurityPlan, user: string, planId: string) => {
    try {
      // Verschlüssle den Sicherheitsplan
      const encryptedSecurityPlan = await encryptData(JSON.stringify(securityPlan));

      // Aktualisiere Sicherheitsplan mit der ID als Schlüssel
      await updateDoc(doc(db, 'securityPlans', user), {
        [`${planId}`]: {
          encryptedSecurityPlan: encryptedSecurityPlan,
        },
      });
    } catch (error) {
      console.error('Securityplan could not be updated: ', error);
    }
  };

  // Lösche Sicherheitsplan
  const deleteSecurityPlan = async (user: string, planId: string) => {
    try {
      await updateDoc(doc(db, 'securityPlans', user), {
        [`${planId}`]: deleteField(),
      });
    } catch (error) {
      console.error('Securityplan could not be deleted: ', error);
    }
  };

  return {
    createOrAddSecurityPlan,
    getSecurityPlan,
    getAllSecurityPlans,
    updateSecurityPlan,
    deleteSecurityPlan,
  };
};

export default useDbSecurityPlan;
