import React, { useContext, useEffect, useState } from 'react';
import { FirebaseUser, UserContext } from '../../contexts/UserContext';
import { useChat } from '../../contexts/ChatContext';
import useDbChat from '../../helpers/database/useDbChat';
import useE2EE from '../../helpers/useE2EE';
import useDbUser from '../../helpers/database/useDbUser';

interface ChatsProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChatSelect?: () => void;
}

export type EncryptedUserChats = {
  chatId: string;
  encryptedUserChat: string;
};

export type UserChats = {
  chatId: string;
  data: {
    userInfo: {
      uid: string;
      displayName: string;
      photoURL: string;
    };
    lastMessage: string;
    timestamp: Date;
  };
};

const Chats: React.FC<ChatsProps> = ({ setIsOpen, onChatSelect }) => {
  const { user: currentUser } = useContext(UserContext);
  const { decryptData } = useE2EE();
  const { getEncryptedUserChats } = useDbChat();
  const { getUser } = useDbUser();
  const { dispatch } = useChat();
  const [encryptedUserChats, setEncryptedUserChats] = useState<EncryptedUserChats[]>([]);
  const [userChats, setUserChats] = useState<UserChats[]>([]);

  // Holle die verschlüsselten Userchats-Daten
  useEffect(() => {
    if (currentUser?.uid) {
      const unsubscribe = getEncryptedUserChats(currentUser.uid, setEncryptedUserChats);

      return () => {
        unsubscribe();
      };
    }
  }, [currentUser?.uid]);

  // Entschlüssle die Userchats-Daten und speichere sie in einem neuen Hook
  useEffect(() => {
    const fetchData = async () => {
      try {
        const chatsArray: UserChats[] = [];

        await Promise.all(
          Object.entries(encryptedUserChats).map(async (chat) => {
            // Lese Daten aus
            const chatId = chat[0];
            const encryptedData = chat[1].encryptedUserChat;

            // Entschlüssle User-Chat Daten
            const decryptedData = await decryptData(encryptedData);
            const data = JSON.parse(decryptedData);

            // Hole von Partner Displayname und PhotoURL aus Users-Collection
            const userInfos = await getUser(data.userInfo.uid);

            // Erstelle ein neues Chat-Objekt, das die chatId als Schlüssel verwendet
            const chatObject: UserChats = {
              chatId,
              data: {
                userInfo: {
                  uid: data.userInfo.uid,
                  displayName: userInfos?.displayName,
                  photoURL: userInfos?.photoURL,
                },
                lastMessage: data.lastMessage ? data.lastMessage : '',
                timestamp: data.timestamp,
              },
            };

            chatsArray.push(chatObject);
          }),
        );

        // Sortiere Einträge nach Timestamp (neuester Eintrag zu oberst)
        chatsArray.sort(
          (a, b) => new Date(b.data.timestamp).getTime() - new Date(a.data.timestamp).getTime(),
        );

        setUserChats(chatsArray);
      } catch (error) {
        console.error('Userchats could not be decrypted: ' + error);
      }
    };

    fetchData();
  }, [encryptedUserChats]);

  useEffect(() => {
    setUserChats(userChats);
  }, [userChats]);

  const handleSelect = (u: FirebaseUser) => {
    dispatch({ type: 'CHANGE_USER', payload: u });
    setIsOpen(false);

    // Melden, dass ein Chat ausgewählt wurde
    if (onChatSelect) {
      onChatSelect();
    }
  };

  return (
    <div className='chats'>
      {Object.entries(userChats)?.map((chat) => (
        <div className='userChat' key={chat[0]} onClick={() => handleSelect(chat[1].data.userInfo)}>
          <img src={chat[1].data.userInfo.photoURL} alt='User' />
          <div className='userChatInfo'>
            <span>{chat[1].data.userInfo.displayName}</span>
            <p>{chat[1].data.lastMessage ? chat[1].data.lastMessage : null}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Chats;
