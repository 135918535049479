import React, { useEffect } from 'react';
import { Routes, Route, Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../helpers/useAuth';
import Login from '../pages/authentication/Login';
import Register from '../pages/authentication/Register';
import Home from '../pages/Home';
import Journal from '../pages/journal/Journal';
import Dayplan from '../pages/journal/Dayplan';
import Review from '../pages/journal/Review';
import PastEntries from '../pages/journal/PastEntries';
import Chat from '../pages/Chatpage';
import Info from '../pages/Info';
import Emergency from '../pages/emergency/Emergency';
import Relaxation from '../pages/emergency/Relaxation';
import EmergencyData from '../pages/emergency/EmergencyData';
import CopingStrategyPlan from '../pages/emergency/CopingStrategyPlan';
import ContactData from '../pages/emergency/ContactData';
import CopingStrategyData from '../pages/emergency/CopingStrategyData';
import { ChatContextProvider } from '../contexts/ChatContext';

function AppRouter() {
  const navigate = useNavigate();
  const { removeCookie, cookies, isAuthenticated, setAuthenticated, setCookie } = useAuth();
  const location = useLocation();

  // Cookie überprüfen und wenn true Weiterleitung handlen
  useEffect(() => {
    if (cookies.cookiesAllowed) {
      if (!cookies.isAuthenticated) {
        return; // Auf Login kicken, wenn die Authentifikation wegfällt
      } else {
        if (
          //Beim Login oder Registrierung auf Home weiterleiten
          cookies.lastVisitedPath === '' ||
          cookies.lastVisitedPath === '/' ||
          cookies.lastVisitedPath === '/login' ||
          cookies.lastVisitedPath === '/register'
        ) {
          navigate('/home');
        } else if (
          //Beim Aktualisieren und F5 Drücken
          cookies.lastVisitedPath === location.pathname + location.search &&
          allRoutes.includes(location.pathname)
        ) {
          navigate(cookies.lastVisitedPath);
        } else if (
          //Manuelles weiterleiten mit Adressfeld
          cookies.lastVisitedPath !== location.pathname + location.search &&
          allRoutes.includes(location.pathname)
        ) {
          navigate(location.pathname);
        } else {
          //Wenn nichts zutrifft (z.B. falsche Eingabe), auf Home weiterleiten - geht nur mit authentifiziertem Cookie
          navigate('/home');
        }
      }
    }
  }, [cookies]);

  // Den lastVisitedPath im Cookie aktualisieren
  useEffect(() => {
    if (cookies.cookiesAllowed && cookies.isAuthenticated) {
      setCookie('lastVisitedPath', location.pathname + location.search, {
        path: '/',
        maxAge: 86400,
      });
    } else if (!isAuthenticated) {
      removeCookie('isAuthenticated');
    }
  }, [location.pathname, location.search]);

  const callbackLogin = () => {
    setAuthenticated(true);
    navigate('/home');
  };

  // Array von allen Routen
  const allRoutes = [
    '/',
    '/login',
    '/register',
    '/home',
    '/journal',
    '/journal/dayplan',
    '/journal/review',
    '/journal/pastentries',
    '/chat',
    '/info',
    '/emergency',
    '/emergency/copingstrategyplan',
    '/emergency/relaxation',
    '/emergency/contactdata',
    '/emergency/emergencydata',
    '/emergency/copingstrategydata',
    '*',
  ];

  return (
    <>
      <Routes>
        <Route path='/' element={<Login callbackLogin={callbackLogin} />} />
        <Route path='/login' element={<Login callbackLogin={callbackLogin} />} />
        <Route path='/register' element={<Register />} />

        <Route element={isAuthenticated ? <Outlet /> : <Navigate to='/login' />}>
          <Route path='/home' element={<Home />} />
          <Route path='/journal' element={<Journal />} />
          <Route path='/journal/dayplan' element={<Dayplan />} />
          <Route path='/journal/review' element={<Review />} />
          <Route path='/journal/pastentries' element={<PastEntries />} />
          <Route
            path='/chat'
            element={
              <ChatContextProvider>
                <Chat />
              </ChatContextProvider>
            }
          />
          <Route path='/info' element={<Info />} />
          <Route path='/emergency' element={<Emergency />} />
          <Route path='/emergency/copingstrategyplan' element={<CopingStrategyPlan />} />
          <Route path='/emergency/relaxation' element={<Relaxation />} />
          <Route path='/emergency/contactdata' element={<ContactData />} />
          <Route path='/emergency/emergencydata' element={<EmergencyData />} />
          <Route path='/emergency/copingstrategydata' element={<CopingStrategyData />} />
        </Route>

        <Route path='*' element={<Login callbackLogin={callbackLogin} />} />
      </Routes>
    </>
  );
}

export default AppRouter;
