import React, { FormEvent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Datepicker from '../../components/journal/Datepicker';
import SliderContainer from '../../components/journal/SliderContainer';
import showToast from '../../components/Toast';
import useJournal from '../../helpers/useJournal';
import useDbJournal from '../../helpers/database/useDbJournal';
import { UserContext } from '../../contexts/UserContext';

const Dayplan = () => {
  const navigate = useNavigate();
  const { updateDayplan, deleteDayplan } = useDbJournal();
  const { date, journalData, existing, setDate, setJournalData } = useJournal();
  const { user: currentUser } = useContext(UserContext);

  // Textareas nach Datenladen neu rendern, sodass Höhe entsprechend dem Inhalt ist
  useEffect(() => {
    const textareaMotto = document.getElementById('motto');
    textareaMotto && M.textareaAutoResize(textareaMotto);
    const textareaGoals = document.getElementById('goals');
    textareaGoals && M.textareaAutoResize(textareaGoals);
  }, [journalData]);

  const handleDateChange = async (newDate: Date) => {
    if (newDate.getTime() !== date.getTime()) {
      setDate(newDate);
    }
  };

  const handleSliderChange = (sliderId: string, value: number) => {
    setJournalData({
      ...journalData,
      sliderData: {
        ...journalData.sliderData,
        [sliderId]: value,
      },
    });
  };

  const handleTextareaChange = (e: { target: { value: string } }, field: string) => {
    setJournalData({ ...journalData, [field]: e.target.value });
  };

  // Lösche Dayplan
  const handleDelete = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (currentUser) {
        await deleteDayplan(currentUser.uid, date);
        showToast('Eintrag gelöscht');
        navigate('/journal');
      }
    } catch (error) {
      console.error('Error deleting dayplan: ' + error);
    }
  };

  // Speichere Dayplan
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (currentUser) {
        await updateDayplan(currentUser.uid, date, JSON.stringify(journalData));
        showToast('Tagesplan gespeichert');
        navigate('/journal');
      }
    } catch (error) {
      console.error('Dayplan could not be saved: ' + error);
    }
  };

  return (
    <div className='page dayplan'>
      <form className='form-styled'>
        <h1>Tagesplan erfassen</h1>
        <div className='flex-group-1'>
          <Datepicker onChange={handleDateChange} initValue={date} />
          <SliderContainer onChange={handleSliderChange} initValues={journalData} />
          <div className='input-field'>
            <textarea
              id='goals'
              className='materialize-textarea'
              value={journalData.goals}
              onChange={(e) => handleTextareaChange(e, 'goals')}
            ></textarea>
            <label htmlFor='goals' className={`${journalData.goals ? 'active' : ''}`}>
              Deine Ziele für den heutigen Tag
            </label>
          </div>
          <div className='input-field'>
            <textarea
              id='motto'
              className='materialize-textarea'
              value={journalData.motto}
              onChange={(e) => handleTextareaChange(e, 'motto')}
            ></textarea>
            <label htmlFor='motto' className={`${journalData.motto ? 'active' : ''}`}>
              Dein Leitsatz für den Tag
            </label>
          </div>
        </div>
        <div className='btn-container'>
          <div className='btn-group'>
            <button
              className='btn-large btn-styled waves-effect waves-light'
              onClick={handleSubmit}
            >
              {existing ? 'Aktualisieren' : 'Speichern'}
            </button>
            <button
              className='btn-large btn-styled waves-effect waves-light'
              onClick={() => navigate('/journal')}
            >
              Abbrechen
            </button>
            {existing && (
              <button
                className='btn-large btn-styled waves-effect waves-light btn-red'
                onClick={handleDelete}
              >
                Tagesplan löschen
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Dayplan;
