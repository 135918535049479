import React, { useState } from 'react';
import Search from './Search';
import Chats from './Chats';
import Icon from '@mdi/react';
import { mdilChevronLeft, mdilChevronRight } from '@mdi/light-js';

interface SidebarChatProps {
  isChatSelected: boolean;
  setIsChatSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarChat: React.FC<SidebarChatProps> = ({ isChatSelected, setIsChatSelected }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  return (
    <div className='sidebar-container2'>
      <div
        className={`sidebar-background ${isSidebarOpen ? '' : 'closed'}`}
        onClick={() => {
          if (isChatSelected) {
            setIsSidebarOpen(!isSidebarOpen);
          }
        }}
      />
      <div className={`sidebar ${isSidebarOpen ? '' : 'closed'}`}>
        <div className='sidebar-container'>
          <Search />
          <Chats
            setIsOpen={setIsSidebarOpen}
            onChatSelect={() => {
              setIsChatSelected(true);
            }}
          />
        </div>
        {isChatSelected && (
          <button className='sidebarToggle' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            {isSidebarOpen ? (
              <Icon path={mdilChevronLeft} size={1} />
            ) : (
              <Icon path={mdilChevronRight} size={1} />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default SidebarChat;
