import React from 'react';

interface CardProps {
  image: string;
  imageAlternative: string;
  title: string;
  leadtext?: string;
}

const Card: React.FC<CardProps> = ({ image, imageAlternative, title, leadtext }) => {
  return (
    <div className='card waves-effect waves-block waves-light'>
      <div className='card-image'>
        <img src={image} alt={imageAlternative} />
      </div>
      <div className='card-content'>
        <span className='card-title'>{title}</span>
        {leadtext && <p>{leadtext}</p>}
      </div>
    </div>
  );
};

export default Card;
