import React, { useEffect } from 'react';

interface DatepickerProps {
  onChange: (date: Date) => void;
  initValue: Date;
}

const Datepicker: React.FC<DatepickerProps> = ({ onChange, initValue }) => {
  useEffect(() => {
    // Initialisiere den Datepicker
    const datePickerElement = document.querySelector('.datepicker');
    if (datePickerElement) {
      M.Datepicker.init(datePickerElement, {
        autoClose: true,
        format: 'dddd, dd. mmmm yyyy',
        i18n: {
          months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
          ],
          monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dez',
          ],
          weekdays: [
            'Sonntag',
            'Montag',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag',
          ],
          weekdaysShort: ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'],
          weekdaysAbbrev: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
          cancel: 'Abbrechen',
          done: 'Bestätigen',
          clear: 'Löschen',
        },
        defaultDate: initValue,
        setDefaultDate: true,

        onSelect: (date) => {
          onChange(date);
        },
      });
    }
  }, [onChange, initValue]);

  return (
    <div className='input-field'>
      <input id='date' type='text' className='datepicker' />
      <label htmlFor='date'>Datum</label>
    </div>
  );
};

export default Datepicker;
