import React, { forwardRef } from 'react';

interface EmergencyProps {
  id: string;
  onClose: () => void;
}

const EmergencyModal = forwardRef<HTMLDivElement, EmergencyProps>((props, ref) => {
  return (
    <div ref={ref} className='modal modal-styled emergency-modal'>
      <div className='modal-title'>Notfallkontakte</div>
      <div className='modal-content'>
        <p className='modal-numbers'>
          <span>Dargebotene Hand: </span>
          <button className='phone-number'>
            <a href='tel:143'>143</a>
          </button>
        </p>
        <p className='modal-numbers'>
          <span>Allgemeiner Notruf: </span>
          <button className='phone-number'>
            <a href='tel:112'>112</a>
          </button>
        </p>
        <p className='modal-numbers'>
          <span>Ambulanz:</span>
          <button className='phone-number'>
            <a href='tel:144'>144</a>
          </button>
        </p>
        <p className='modal-numbers'>
          <span>Polizei:</span>
          <button className='phone-number'>
            <a href='tel:117'>117</a>
          </button>
        </p>
        <p className='modal-numbers'>
          <span>Angst- und Panikhilfe Schweiz: </span>
          <button className='phone-number'>
            <a href='tel:0848801109'>0848 801 109</a>
          </button>
        </p>
      </div>
      <div className='modal-footer'>
        <button className='waves-effect waves-green btn-flat' onClick={props.onClose}>
          Schliessen
        </button>
      </div>
    </div>
  );
});

export default EmergencyModal;
