import React, { forwardRef } from 'react';

type Props = {
  id: string;
  mode: 'edit' | 'delete' | null;
  onConfirm: (value?: string) => void;
  onCancel: () => void;
  defaultValue?: string;
};

const MessageModal = forwardRef<HTMLDivElement, Props>((props, ref) => {
  if (props.mode === null) return null;

  let content;

  if (props.mode === 'edit') {
    content = (
      <form className='form-styled'>
        <div className='input-field'>
          <label htmlFor='editMessageInput' className={`${props.defaultValue ? 'active' : ''}`}>
            Neuer Nachrichtentext
          </label>
          <input id='editMessageInput' type='text' defaultValue={props.defaultValue} />
        </div>
      </form>
    );
  } else if (props.mode === 'delete') {
    content = <p>Sind Sie sicher, dass Sie diese Nachricht löschen möchten?</p>;
  }

  return (
    <div ref={ref} className='modal modal-styled modal-small'>
      {props.mode === 'edit' ? (
        <div className='modal-title'>Nachricht bearbeiten</div>
      ) : (
        <div className='modal-title'>Nachricht löschen</div>
      )}
      <div className='modal-content'>{content}</div>
      <div className='modal-footer'>
        <button
          className='waves-effect waves-red btn-flat'
          onClick={() => {
            if (props.mode === 'edit') {
              const newValue = (document.getElementById('editMessageInput') as HTMLInputElement)
                .value;
              props.onConfirm(newValue);
            } else if (props.mode === 'delete') {
              props.onConfirm();
            }
          }}
        >
          Bestätigen
        </button>
        <button className='waves-effect waves-green btn-flat' onClick={props.onCancel}>
          Abbrechen
        </button>
      </div>
    </div>
  );
});

export default MessageModal;
