import React, { useContext, useEffect, useState } from 'react';
import Logout from './Logout';
import { UserContext } from '../contexts/UserContext';

const Sidebar: React.FC = () => {
  const currentUser = useContext(UserContext);
  const [currentUserState, setCurrentUserState] = useState(currentUser);

  // Überwachen von Änderungen an currentUser von aussen
  useEffect(() => {
    setCurrentUserState(currentUser);
  }, [currentUser]);

  return (
    <div className='sidebar center-align'>
      <div>
        <div className='displayname'>
          <p>Zurzeit angemeldet als:</p>
          <p>{currentUserState.user?.displayName}</p>
        </div>
        <div className='avatar z-depth-1'>
          {currentUserState.user?.photoURL ? (
            <img src={currentUserState.user.photoURL} alt='Avatar' />
          ) : null}
        </div>
        <button
          className='btn-large btn-styled waves-effect waves-light modal-trigger'
          data-target='editProfileModal'
        >
          Profil bearbeiten
        </button>
      </div>
      <Logout />
    </div>
  );
};

export default Sidebar;
