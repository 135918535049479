import React, { useEffect, useState } from 'react';
import CardInfo from '../components/information/CardInfo';
import useDbBlog, { Blog } from '../helpers/database/useDbBlog';

const Info = () => {
  const { getBlogEntries, countBlogEntries } = useDbBlog();
  const [numberOfEntries, setNumberOfEntries] = useState<number>(3);
  const [maxNumberOfEntries, setMaxNumberOfEntries] = useState<number>(3);
  const [blogs, setBlogs] = useState<Blog[]>([]);

  // Hole beim 1. Laden die neuesten 5 Beiträge
  useEffect(() => {
    getBlogEntries(numberOfEntries).then((data) => setBlogs(data));
    countBlogEntries().then((data) => setMaxNumberOfEntries(data));
  }, []);

  // Hole bei Button-Klick die 3 nächst älteren Beiträge
  const handleLoad = () => {
    setNumberOfEntries(numberOfEntries + 3);
  };

  useEffect(() => {
    setNumberOfEntries(numberOfEntries)
    getBlogEntries(numberOfEntries).then((data) => setBlogs(data));
  }, [numberOfEntries]);

  return (
    <div className='page information'>
      <div className='card-container'>
        {blogs.map((blog) => (
          <CardInfo key={blog.blogId} data={blog.data} />
        ))}
      </div>
      {maxNumberOfEntries > numberOfEntries && (
        <div className='btn-container'>
          <div className='btn-group'>
            <button className='btn-large btn-styled waves-effect waves-light' onClick={handleLoad}>
              Weitere Beiträge laden
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Info;
