import React, { useContext, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useChat } from '../../contexts/ChatContext';
import useAuth from '../../helpers/useAuth';
import useDbImage from '../../helpers/database/useDbImage';
import useDbChat from '../../helpers/database/useDbChat';
import Icon from '@mdi/react';
import { mdilImage, mdilArrowRightCircle } from '@mdi/light-js';
import showToast from '../Toast';
import Spinner from '../Spinner';

const Input: React.FC = () => {
  const { uploadImage } = useDbImage();
  const { updateChat, updateUserChat } = useDbChat();
  const [text, setText] = useState('');
  const { validatePictureDirect } = useAuth();
  const { user: currentUser } = useContext(UserContext);
  const [isSpinnerOpen, setSpinnerOpen] = useState<boolean>(false);
  const {
    data: { user, chatId },
  } = useChat();
  const isChatSelected = user && chatId;

  // Nachricht senden
  const handleSend = async () => {
    if (!user || !chatId) {
      alert('Bitte wählen Sie zuerst einen Chat aus, bevor Sie eine Nachricht senden. Danke!');
      return;
    }

    if (currentUser?.uid) {
      await updateChat(chatId, currentUser, text);
    }

    if (currentUser?.uid) {
      await updateUserChat(currentUser.uid, chatId, text);
      await updateUserChat(user.uid, chatId, text);
    }

    setText('');
  };

  return (
    <div className='input'>
      <input
        type='text'
        placeholder='Nachricht'
        className='inputChat'
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div className='send'>
        <input
          id='chatimage'
          type='file'
          style={{ display: 'none' }}
          onChange={async (e) => {
            if (e.target.files && e.target.files.length > 0) {
              setSpinnerOpen(true);
              // Überprüfen, ob das Bild gültig ist und hochladen
              const message = await validatePictureDirect(e.target.files[0]);

              if (message !== '') {
                showToast(message);
                setTimeout(() => {
                  setSpinnerOpen(false);
                }, 1000);
              } else {
                // Bild hochladen, nachdem es validiert wurde
                const photoURL = await uploadImage(e.target.files[0]);

                // Bild hochladen und Textnachricht senden
                if (currentUser?.uid) {
                  await updateChat(chatId, currentUser, text, photoURL);
                }
                setText('');
                setSpinnerOpen(false);
              }
            }
          }}
        />
        <label htmlFor='chatimage'>
          <Icon path={mdilImage} size={1} className='icon' />
        </label>
        <button
          type='submit'
          onClick={handleSend}
          disabled={!isChatSelected}
          className='waves-effect waves-light '
        >
          <Icon path={mdilArrowRightCircle} size={1} className='icon' />
        </button>
      </div>
      <Spinner isOpen={isSpinnerOpen} />
    </div>
  );
};

export default Input;
