import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeelingsStatistic from '../../components/journal/FeelingsStatistic';
import useJournal from '../../helpers/useJournal';
import { UserContext } from '../../contexts/UserContext';
import Card from '../../components/Card';
import IconSun from '../../assets/Journal_Sun.svg';
import IconMoon from '../../assets/Journal_Moon.svg';
import IconArrow from '../../assets/Journal_Arrow.svg';

const Journal = () => {
  const { user: currentUser } = useContext(UserContext);
  const {
    getAverageFeelingsDayplan,
    getAverageFeelingsReview,
    constructFeelingArray,
    filterFeelingArrays,
  } = useJournal();
  const [labels, setLabels] = useState<string[]>();
  const [averageFeelingsDayplan, setAverageFeelingsDayplan] = useState<number[]>();
  const [averageFeelingsReview, setAverageFeelingsReview] = useState<number[]>();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          // Hole Dayplan Gefühlsdaten
          const dayplanData = await getAverageFeelingsDayplan();
          const dayplanArray = await constructFeelingArray(dayplanData);

          // Hole Review Gefühlsdaten
          const reviewData = await getAverageFeelingsReview();
          const reviewArray = await constructFeelingArray(reviewData);

          const { filteredDayplanArray, filteredReviewArray } = await filterFeelingArrays(
            dayplanArray,
            reviewArray,
          );

          setAverageFeelingsDayplan(Object.values(filteredDayplanArray));
          setAverageFeelingsReview(Object.values(filteredReviewArray));
          setLabels(Object.keys(filteredDayplanArray));
        } catch (error) {
          console.error('Beim Laden von Dayplan/Review ist ein Fehler aufgetreten: ', error);
        }
      }
    };
    fetchData();
  }, [currentUser]);

  return (
    <div className='page journal'>
      <div className='flex-group-1'>
        <Link to='/journal/dayplan'>
          <Card
            image={IconSun}
            imageAlternative='Sonne'
            title='Tagesplan erfassen'
            leadtext='Erstelle deinen Plan für den Tag.'
          />
        </Link>
        <Link to='/journal/review'>
          <Card
            image={IconMoon}
            imageAlternative='Mond'
            title='Rückblick erfassen'
            leadtext='Fasse deinen Tag zusammen.'
          />
        </Link>
        <Link to='/journal/pastentries'>
          <Card image={IconArrow} imageAlternative='Bücher' title='Vergangene Einträge' />
        </Link>
      </div>
      <div className='flex-group-2'>
        {labels && labels.length > 0 && (
          <FeelingsStatistic
            labels={labels}
            averageFeelingsDayplan={averageFeelingsDayplan}
            averageFeelingsReview={averageFeelingsReview}
          />
        )}
      </div>
    </div>
  );
};

export default Journal;
