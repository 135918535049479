import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../useFirebase';
import { v4 as uuid } from 'uuid';

const useDbImage = () => {
  // Lade Bild aus Chat-Unterhaltung hoch
  const uploadImage = (img: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, uuid());
      const uploadTask = uploadBytesResumable(storageRef, img);

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error('Image could not be uploaded: ', error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        },
      );
    });
  };

  // Lade Avatar-Bild in Profil hoch
  const uploadImageAvatar = (img: File, uid: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, uid);
      const uploadTask = uploadBytesResumable(storageRef, img);

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        },
      );
    });
  };

  // Bild Löschen vom Storage
  const deleteImageFromStorage = async (imageUrl: string): Promise<void> => {
    const imagePath = imageUrl.split('/o/')[1].split('?')[0];
    const imageRef = ref(storage, imagePath);

    try {
      await deleteObject(imageRef);
    } catch (error) {
      console.error('Error deleting image from storage:', error);
    }
  };

  return { uploadImage, uploadImageAvatar, deleteImageFromStorage };
};

export default useDbImage;
