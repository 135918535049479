import { UserCredential, updateProfile } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { auth, db } from '../useFirebase';
import { FirebaseUser } from '../../contexts/UserContext';
import useDbChat from './useDbChat';

const useDbUser = () => {
  const { createUserChat } = useDbChat();

  // Erstelle einen neuen Benutzer in der DB
  const createUser = async (
    userCredential: UserCredential,
    displayName: string,
    downloadURL: string,
  ) => {
    try {
      await updateProfile(userCredential.user, {
        displayName,
        photoURL: downloadURL,
      });
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        displayName,
        photoURL: downloadURL,
      });
      createUserChat(userCredential.user.uid);
    } catch (error) {
      console.error('User could not be created: ', error);
    }
  };

  // Erhalte Userdaten anhand Benutzernamen aus DB. Wird für die Suche benötigt.
  // Erstellt ein FirebaseUser-Array und gibt es zurück
  const getUsers = async (username: string): Promise<FirebaseUser[]> => {
    try {
      const q = query(
        collection(db, 'users'),
        where('displayName', '>=', username),
        where('displayName', '<', username + '~'),
      );
      const querySnapshot = await getDocs(q);
      const users: FirebaseUser[] = [];
      querySnapshot.forEach((doc) => {
        users.push({
          uid: doc.id,
          displayName: doc.data().displayName,
          photoURL: doc.data().photoURL,
        });
      });
      return users;
    } catch (error) {
      console.error('Could not find any entries: ', error);
      return [];
    }
  };

  // Erhalte User anhand UID aus der DB
  // Gibt passenden Benutzer zurück
  const getUser = async (uid: string) => {
    try {
      const usersDoc = await getDoc(doc(db, 'users', uid));

      if (usersDoc.exists()) {
        const usersData = usersDoc.data();
        return usersData;
      } else {
        // Wenn keine Daten zum Benutzer vorhanden sind
        console.error('Benutzerdaten existieren nicht');
        return null;
      }
    } catch (error) {
      console.error('User data could not be retrieved: ', error);
    }
  };

  // Aktualisiere Userdaten in der DB
  const updateUser = async (displayName: string, downloadURL?: string) => {
    try {
      const user = auth.currentUser;

      if (user) {
        // Aktualisiere Profil von Firebase-User
        await updateProfile(user, {
          displayName: displayName,
          photoURL: downloadURL ? downloadURL : user.photoURL,
        });

        // Aktualisiere Usersdaten in der DB
        await setDoc(doc(db, 'users', user.uid), {
          displayName: displayName,
          photoURL: downloadURL ? downloadURL : user?.photoURL,
        });
      }
    } catch (error) {
      console.error('User profile could not be updated: ', error);
    }
  };

  // Überprüfe, ob mitgegebener Anzeigename bereits in DB existiert
  const isDisplayNameUnique = async (displayName: string) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('displayName', '==', displayName));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error('Could not check if displayname already exisists: ', error);
    }
  };

  return { createUser, getUsers, getUser, updateUser, isDisplayNameUnique };
};

export default useDbUser;
