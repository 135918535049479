import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface FeelingsStatisticProps {
  labels: string[] | undefined;
  averageFeelingsDayplan: number[] | undefined;
  averageFeelingsReview: number[] | undefined;
}

const FeelingsStatistic: React.FC<FeelingsStatisticProps> = ({
  labels,
  averageFeelingsDayplan,
  averageFeelingsReview,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Gefühle morgens',
        data: averageFeelingsDayplan,
        fill: true,
        backgroundColor: 'rgba(255, 213, 79, 0.4)',
        borderColor: 'rgb(255, 213, 79)',
        borderWidth: 1,
        pointBackgroundColor: 'rgb(255, 213, 79)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 213, 79)',
      },
      {
        label: 'Gefühle abends',
        data: averageFeelingsReview,
        fill: true,
        backgroundColor: 'rgba(249, 167, 106, 0.4)',
        borderColor: 'rgb(249, 167, 106)',
        borderWidth: 1,
        pointBackgroundColor: 'rgb(249, 167, 106)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(249, 167, 106)',
      },
    ],
  };
  const options = {
    scales: {
      r: {
        ticks: {
          stepSize: 1,
          font: {
            size: 12,
          },
        },
        pointLabels: {
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  return <Radar data={data} options={options} />;
};

export default FeelingsStatistic;
