import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Contact } from './ContactData';
import { useUser } from '../../contexts/UserContext';
import useDbContact from '../../helpers/database/useDbContact';
import useDbSecurityPlan from '../../helpers/database/useDbSecurityPlan';
import { showToast } from '../../components/Toast';
import NewContactModal from '../../components/modals/NewContactModal';
import Icon from '@mdi/react';
import { mdilPlusCircle, mdilInformation } from '@mdi/light-js';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    M: any;
  }
}

export type SecurityPlan = {
  planId: string;
  data: {
    title: string; //titel:
    warningSigns: string; //warnzeichen:
    calmingStrategies: string; //beruhigungsstrategien:
    safePlace: string; //sicherer_ort:
    contacts: string[] | Contact[]; //kontakte:
  };
};

const CopingStrategyData: React.FC = () => {
  const navigate = useNavigate();
  const user = useUser();
  const [modalInstance, setModalInstance] = useState<M.Modal | null>(null);
  const { getAllContacts } = useDbContact();
  const { createOrAddSecurityPlan, getSecurityPlan, updateSecurityPlan, deleteSecurityPlan } =
    useDbSecurityPlan();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isInfo, setIsInfo] = useState(false);
  const location = useLocation();
  const editingContactId = new URLSearchParams(location.search).get('editingPlan');
  const isEditingMode = Boolean(editingContactId);
  const headerDescription = isEditingMode
    ? 'Aktualisiere oder entferne deinen Strategieplan.'
    : 'Halte hier deine Strategien fest, welche dir in einem Notfall weiter helfen können.';
  const buttonText = isEditingMode ? 'Aktualisieren' : 'Speichern';

  const [plan, setPlan] = useState<SecurityPlan>({
    planId: '',
    data: {
      title: '',
      warningSigns: '',
      calmingStrategies: '',
      safePlace: '',
      contacts: [],
    },
  });

  // Lade Kontakte aus der DB
  useEffect(() => {
    const fetchData = async () => {
      if (user.user?.uid) {
        const contacts = await getAllContacts(user.user.uid);
        setContacts(contacts);
      }
    };

    fetchData();
  }, []);

  // Lade den zu bearbeitenden Sicherheitsplan aus der DB
  useEffect(() => {
    const fetchSecurityPlan = async () => {
      if (isEditingMode && user.user?.uid && editingContactId) {
        const existingPlan = await getSecurityPlan(user.user.uid, editingContactId);
        if (existingPlan) {
          setPlan(existingPlan);
        }
      }
    };

    fetchSecurityPlan();
  }, []);

  // Initialisiere das Dropdown-Menü von Materialize sobald die Kontakte geladen wurden
  useEffect(() => {
    initDropDown();
  }, [contacts]);

  const initDropDown = () => {
    const elems = document.querySelectorAll('select');
    if (elems) {
      console;
      window.M.FormSelect.init(elems, { isMultiple: true });
    }
  };

  // Textareas nach Datenladen neu rendern, sodass Höhe entsprechend dem Inhalt ist
  useEffect(() => {
    const textareaWarningSigns = document.getElementById('warningSigns');
    textareaWarningSigns && M.textareaAutoResize(textareaWarningSigns);
    const textareaCalmingStrategies = document.getElementById('calmingStrategies');
    textareaCalmingStrategies && M.textareaAutoResize(textareaCalmingStrategies);
  }, [plan]);

  // Kontakte auswählen
  const handleContactSelection = () => {
    const elems = document.querySelector('select');
    if (elems) {
      const instance = window.M.FormSelect.getInstance(elems);
      const selectedIds = instance.getSelectedValues();
      const selectedContacts = contacts.filter((contact) =>
        selectedIds.includes(contact.contactId),
      );
      setPlan((prevPlan) => ({
        ...prevPlan,
        data: {
          ...plan.data,
          contacts: selectedContacts.map((c) => c.contactId),
        },
      }));
    }
  };

  // UUID generieren und dem Sicherheitsplan anfügen
  useEffect(() => {
    try {
      if (user) setPlan((prevPlan) => ({ ...prevPlan, planId: uuid() }));
    } catch (error) {
      console.error('Error setPlan:', error);
    }
  }, []);

  // Plan Hinzufügen / Aktualisieren
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (user.user?.uid) {
        if (isEditingMode && editingContactId) {
          await updateSecurityPlan(plan, user.user.uid, editingContactId);
        } else {
          await createOrAddSecurityPlan(user.user.uid, plan.planId, plan);
        }
      }
    } catch (error) {
      console.error('Error saving security plan', error);
      showToast('Fehler beim Speichern des Sicherheitsplans');
    }

    // Zurück zur Übersicht
    navigate('/emergency/copingstrategyplan');

    // Eingaben zurücksetzen
    setPlan({
      planId: '',
      data: {
        title: '',
        warningSigns: '',
        calmingStrategies: '',
        safePlace: '',
        contacts: [],
      },
    });
    showToast(isEditingMode ? 'Sicherheitsplan aktualisiert' : 'Sicherheitsplan gespeichert');
  };

  const handleDelete = async () => {
    if (user.user?.uid && editingContactId) {
      try {
        await deleteSecurityPlan(user.user.uid, editingContactId);
        navigate('/emergency/copingstrategyplan');
        showToast('Sicherheitsplan gelöscht');
      } catch (error) {
        console.error('Error deleting security plan', error);
      }
    }
  };

  // Modal Initialisierung
  const modalRef = useRef<HTMLDivElement>(null);

  // Modal öffnen
  const onModalOpen = () => {
    if (modalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(modalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };

  // Toggle-Funktion für das Anzeigen der Information
  const toggleInfo = () => {
    setIsInfo((prevIsInfo) => !prevIsInfo);
  };

  return (
    <div className='page'>
      <h1>{isEditingMode ? 'Strategieplan bearbeiten' : 'Strategieplan erfassen'}</h1>
      <p>
        {headerDescription}
        <span className='info-icon' onClick={toggleInfo}>
          {' '}
          <Icon path={mdilInformation} size={1} />
        </span>
      </p>
      {isInfo && (
        <div className='info-content'>
          <p>
            <strong>Titel: </strong> Beschreibe kurz, für welche spezifischen Ereignisse oder
            Umstände dieser Strategieplan gedacht ist.
          </p>
          <p>
            <strong>Warnzeichen: </strong> Welche Anzeichen deuten für dich darauf hin, dass sich
            eine kritische Situation entwickelt? Denke an Verhaltensweisen, Gedanken oder Gefühle,
            die als Frühwarnzeichen dienen können.
          </p>
          <p>
            <strong>Beruhigungsstrategien: </strong> Liste Massnahmen auf, die dir helfen, dich in
            schwierigen Momenten zu beruhigen und zu stabilisieren. Was bringt dich zurück in die
            Balance? Überlege, welche Aktivitäten oder Gedanken dir Halt geben.
          </p>
          <p>
            <strong>Sicherer Ort: </strong> Definiere einen Ort, an dem du dich vollkommen sicher
            fühlst. Dies kann ein realer Raum oder ein Platz in deiner Vorstellung sein. Überlege
            auch, was getan werden kann, um diesen Ort sicherer zu machen.
          </p>
          <p>
            <strong>Kontaktieren: </strong> Wähle Personen aus deiner Kontaktliste aus, die dich in
            einer Krise unterstützen können. Wer sollte benachrichtigt werden? Denke an Familie,
            Freunde oder medizinisches Personal.
          </p>
        </div>
      )}
      <form className='form-styled' onSubmit={handleSubmit}>
        <div className='input-field'>
          <label htmlFor='title' className={`${plan.data.title ? 'active' : ''}`}>
            Titel
          </label>
          <input
            id='title'
            type='text'
            value={plan.data.title}
            onChange={(e) =>
              setPlan({
                ...plan,
                data: {
                  ...plan.data,
                  title: e.target.value,
                },
              })
            }
          />
        </div>
        <div className='input-field'>
          <textarea
            id='warningSigns'
            className='materialize-textarea'
            value={plan.data.warningSigns}
            onChange={(e) =>
              setPlan({
                ...plan,
                data: {
                  ...plan.data,
                  warningSigns: e.target.value,
                },
              })
            }
          />
          <label htmlFor='warningSigns' className={`${plan.data.warningSigns ? 'active' : ''}`}>
            Warnzeichen
          </label>
        </div>
        <div className='input-field'>
          <textarea
            id='calmingStrategies'
            className='materialize-textarea'
            value={plan.data.calmingStrategies}
            onChange={(e) =>
              setPlan({
                ...plan,
                data: {
                  ...plan.data,
                  calmingStrategies: e.target.value,
                },
              })
            }
          />
          <label
            htmlFor='calmingStrategies'
            className={`${plan.data.calmingStrategies ? 'active' : ''}`}
          >
            Beruhigungsstrategien
          </label>
        </div>
        <div className='input-field'>
          <label htmlFor='safePlace' className={`${plan.data.safePlace ? 'active' : ''}`}>
            Sicherer Ort{' '}
          </label>
          <input
            id='safePlace'
            type='text'
            value={plan.data.safePlace}
            onChange={(e) =>
              setPlan({
                ...plan,
                data: {
                  ...plan.data,
                  safePlace: e.target.value,
                },
              })
            }
          />
        </div>

        <div className='input-field'>
          <label htmlFor='contacts' className={`${plan.data.contacts ? 'active' : ''}`}>
            Kontaktieren
          </label>
          <select id='contacts' multiple onChange={handleContactSelection}>
            {contacts.map((contact) => (
              <option key={contact.contactId} value={contact.contactId}>
                {contact.data.name}
              </option>
            ))}
          </select>
          <span className='add-contact' onClick={onModalOpen}>
            <Icon path={mdilPlusCircle} size={1} /> Neuen Kontakt erfassen
          </span>
        </div>
        <div className='btn-container'>
          <div className='btn-group'>
            <button type='submit' className='btn-large btn-styled waves-effect waves-light'>
              {buttonText}
            </button>
            <button
              type='button'
              className='btn-large btn-styled waves-effect waves-light'
              onClick={() => navigate('/emergency/copingstrategyplan')}
            >
              Abbrechen
            </button>
            {isEditingMode && (
              <button
                type='button'
                className='btn-large btn-styled waves-effect waves-light btn-red'
                onClick={handleDelete}
              >
                Strategieplan löschen
              </button>
            )}
          </div>
        </div>
      </form>
      <NewContactModal
        id='newContactModal'
        ref={modalRef}
        onConfirm={() => navigate('/emergency/contactdata')}
        onCancel={() => navigate('/emergency/copingstrategydata')}
      />
    </div>
  );
};
export default CopingStrategyData;
