import React from 'react';
import Slider from './Slider';
import { JournalData } from '../../helpers/useJournal';

interface SliderContainerProps {
  onChange: (sliderId: string, value: number) => void;
  initValues: JournalData;
}

const SliderContainer: React.FC<SliderContainerProps> = ({ onChange, initValues }) => {
  return (
    <div>
      <label>Wie fühlst du dich gerade?</label>
      <Slider
        id='slider1'
        label1='Freude'
        label2='Trauer'
        onChange={onChange}
        initValue={initValues.sliderData.slider1}
      />
      <Slider
        id='slider2'
        label1='Liebe'
        label2='Hass'
        onChange={onChange}
        initValue={initValues.sliderData.slider2}
      />
      <Slider
        id='slider3'
        label1='Hoffnung'
        label2='Verzweiflung'
        onChange={onChange}
        initValue={initValues.sliderData.slider3}
      />
      <Slider
        id='slider4'
        label1='Geborgenheit'
        label2='Einsamkeit'
        onChange={onChange}
        initValue={initValues.sliderData.slider4}
      />
      <Slider
        id='slider5'
        label1='Aufgewecktheit'
        label2='Müdigkeit'
        onChange={onChange}
        initValue={initValues.sliderData.slider5}
      />
      <Slider
        id='slider6'
        label1='Interessiertheit'
        label2='Antriebslosigkeit'
        onChange={onChange}
        initValue={initValues.sliderData.slider6}
      />
      <Slider
        id='slider7'
        label1='Entspanntheit'
        label2='Stress'
        onChange={onChange}
        initValue={initValues.sliderData.slider7}
      />
      <Slider
        id='slider8'
        label1='Mut'
        label2='Angst'
        onChange={onChange}
        initValue={initValues.sliderData.slider8}
      />
    </div>
  );
};

export default SliderContainer;
