import { onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../helpers/useFirebase';
import useAuth from '../helpers/useAuth';

export type FirebaseUser = {
  uid: string;
  displayName: string;
  photoURL: string;
};

export type UserContextType = {
  user: FirebaseUser | null;
  setUser: React.Dispatch<React.SetStateAction<FirebaseUser | null>>;
};

type UserProviderType = {
  children: React.ReactNode;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {
    ('');
  },
});

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children }: UserProviderType) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const { uid, displayName, photoURL } = user;

        if (
          displayName !== null &&
          displayName !== undefined &&
          photoURL !== null &&
          photoURL !== undefined
        ) {
          setCurrentUser({
            uid,
            displayName: displayName,
            photoURL: photoURL,
          });
        }
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, [isAuthenticated]);

  return (
    <UserContext.Provider value={{ user: currentUser, setUser: setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};
