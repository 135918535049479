import React, { useEffect, useState } from 'react';
import useDbJournal from '../../helpers/database/useDbJournal';
import { useUser } from '../../contexts/UserContext';
import Icon from '@mdi/react';
import { mdilPencil } from '@mdi/light-js';
import { useNavigate } from 'react-router-dom';

const PastEntries: React.FC = () => {
  const { getAllEntries } = useDbJournal();
  const navigate = useNavigate();
  const [entries, setEntries] = useState<
    Record<
      string,
      {
        dayplan: string;
        review: string;
      }
    >
  >({});
  const [sortedDates, setSortedDates] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const { user } = useUser();

  // Alle Einträge einer UID holen und in State speichern
  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      const allEntries = await getAllEntries(user.uid);
      setEntries(allEntries);
    };

    fetchData();
  }, []);

  // Entries sortieren und in umgekehrter Reihenfolge ausgeben
  useEffect(() => {
    if (entries && Object.keys(entries).length > 0) {
      const sorted = Object.keys(entries)
        .filter((date) => !isNaN(new Date(date).getTime())) // Filtert ungültige Daten aus
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reverse();
      setSortedDates(sorted);
    }
  }, [entries]);

  // Datum in schönerer Formatierung darstellen
  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return new Intl.DateTimeFormat('de-DE', options).format(date);
  };

  // Edit Dayplan
  const handleEditDayplan = async (date: Date) => {
    navigate(`/journal/dayplan?editingDate=${date}`);
  };

  // Edit Review
  const handleEditReview = (date: Date) => {
    navigate(`/journal/review?editingDate=${date}`);
  };

  type FeelingMap = {
    [key: number]: {
      '+': string;
      '-': string;
    };
  };

  const sliderFeelings: FeelingMap = {
    1: { '+': 'Trauer', '-': 'Freude' },
    2: { '+': 'Hass', '-': 'Liebe' },
    3: { '+': 'Verzweiflung', '-': 'Hoffnung' },
    4: { '+': 'Einsamkeit', '-': 'Geborgenheit' },
    5: { '+': 'Müdigkeit', '-': 'Aufgewecktheit' },
    6: { '+': 'Antriebslosigkeit', '-': 'Interessiertheit' },
    7: { '+': 'Stress', '-': 'Entspanntheit' },
    8: { '+': 'Angst', '-': 'Mut' },
  };

  // Gefühle
  const mood = (value: string, slider: number) => {
    let sign: '+' | '-';

    if (value === '0' || value === '' || value === undefined || value === null) {
      sign = '-';
    } else {
      const stringValue = String(value);
      sign = stringValue.startsWith('-') ? '-' : '+';
    }
    const feeling = sliderFeelings[slider][sign];
    const boxes = parseInt(value.replace(/[+-]/g, ''), 10);

    const boxesComponent = [];
    for (let i = 1; i <= 5; i++) {
      boxesComponent.push(
        <div
          key={i}
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: i <= boxes ? '#f58f76' : 'lightgray',
            display: 'inline-block',
            margin: '1px',
          }}
        ></div>,
      );
    }

    return (
      <>
        {boxesComponent}
        <span className='feeling'>{feeling}</span>
      </>
    );
  };

  return (
    <div className='page '>
      <div className='content-header'>
        <h1>Vergangene Einträge</h1>
        <p>Hier kannst du in deinen vergangenen Einträgen stöbern.</p>
      </div>
      <div className='pastentries'>
        <ul className='pastentries-list'>
          {sortedDates.map((date) => {
            const dateCastToDate = new Date(date);
            const formattedDate = formatDate(dateCastToDate);

            // Initialwert setzen
            let achievements = '';
            let summary = '';
            let motto = '';
            let goals = '';

            let dpslider1 = '';
            let dpslider2 = '';
            let dpslider3 = '';
            let dpslider4 = '';
            let dpslider5 = '';
            let dpslider6 = '';
            let dpslider7 = '';
            let dpslider8 = '';

            let rwslider1 = '';
            let rwslider2 = '';
            let rwslider3 = '';
            let rwslider4 = '';
            let rwslider5 = '';
            let rwslider6 = '';
            let rwslider7 = '';
            let rwslider8 = '';

            // Überprüfung, ob dayplan existiert
            if (entries[date].dayplan) {
              const entryDP = JSON.parse(entries[date].dayplan);
              motto = entryDP.motto || '';
              goals = entryDP.goals || '';
              dpslider1 = entryDP.sliderData.slider1 || '';
              dpslider2 = entryDP.sliderData.slider2 || '';
              dpslider3 = entryDP.sliderData.slider3 || '';
              dpslider4 = entryDP.sliderData.slider4 || '';
              dpslider5 = entryDP.sliderData.slider5 || '';
              dpslider6 = entryDP.sliderData.slider6 || '';
              dpslider7 = entryDP.sliderData.slider7 || '';
              dpslider8 = entryDP.sliderData.slider8 || '';
            }

            // Überprüfung, ob review existiert
            if (entries[date].review) {
              const entryRW = JSON.parse(entries[date].review);
              achievements = entryRW.achievements || '';
              summary = entryRW.summary || '';
              rwslider1 = entryRW.sliderData.slider1 || '';
              rwslider2 = entryRW.sliderData.slider2 || '';
              rwslider3 = entryRW.sliderData.slider3 || '';
              rwslider4 = entryRW.sliderData.slider4 || '';
              rwslider5 = entryRW.sliderData.slider5 || '';
              rwslider6 = entryRW.sliderData.slider6 || '';
              rwslider7 = entryRW.sliderData.slider7 || '';
              rwslider8 = entryRW.sliderData.slider8 || '';
            }

            return (
              <li className='pastentries-item' key={date}>
                <h2 onClick={() => setSelectedDate(date === selectedDate ? null : date)}>
                  {formattedDate}
                </h2>
                {date === selectedDate && (
                  <>
                    <div className='pastentries-box'>
                      <div className='pastentries-icon-container'>
                      <div className='pastentries-icon'
                        onClick={() => handleEditDayplan(dateCastToDate)}
                      >
                        <Icon
                          path={mdilPencil}
                          size={1}
                        />
                      </div>
                      </div>
                      <div className='pastentries-element'>
                        <span className='bold'>Leitsatz des Tages:</span>
                        <div className='pastentries-content'>{motto}</div>
                      </div>
                      <div className='pastentries-element'>
                        <span className='bold'>Ziele:</span>
                        <div className='pastentries-content'>{goals}</div>
                      </div>
                      <div className='pastentries-element'>
                        <span className='bold'>Gefühle:</span>
                        <div className='pastentries-content'>
                          {mood(dpslider1.toString(), 1)}
                          <br />
                          {mood(dpslider2.toString(), 2)}
                          <br />
                          {mood(dpslider3.toString(), 3)}
                          <br />
                          {mood(dpslider4.toString(), 4)}
                          <br />
                          {mood(dpslider5.toString(), 5)}
                          <br />
                          {mood(dpslider6.toString(), 6)}
                          <br />
                          {mood(dpslider7.toString(), 7)}
                          <br />
                          {mood(dpslider8.toString(), 8)}
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className='pastentries-box'>
                      <div className='pastentries-icon-container'>
                        <div
                          className='pastentries-icon'
                          onClick={() => handleEditReview(dateCastToDate)}
                        >
                          <Icon path={mdilPencil} size={1} />
                        </div>
                      </div>
                      <div className='pastentries-element'>
                        <span className='bold'>Erreichte Ziele:</span>
                        <div className='pastentries-content'>{achievements}</div>
                      </div>
                      <div className='pastentries-element'>
                        <span className='bold'>Tagebuch:</span>
                        <div className='pastentries-content'>{summary}</div>
                      </div>
                      <div className='pastentries-element'>
                        <span className='bold'>Gefühle:</span>
                        <div className='pastentries-content'>
                          {mood(rwslider1.toString(), 1)}
                          <br />
                          {mood(rwslider2.toString(), 2)}
                          <br />
                          {mood(rwslider3.toString(), 3)}
                          <br />
                          {mood(rwslider4.toString(), 4)}
                          <br />
                          {mood(rwslider5.toString(), 5)}
                          <br />
                          {mood(rwslider6.toString(), 6)}
                          <br />
                          {mood(rwslider7.toString(), 7)}
                          <br />
                          {mood(rwslider8.toString(), 8)}
                          <br />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PastEntries;
