import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar: React.FC = () => {
  const [iconJournalHovered, setIconJournalHovered] = useState<boolean>(false);
  const [iconChatHovered, setIconChatHovered] = useState<boolean>(false);
  const [iconInfoHovered, setIconInfoHovered] = useState<boolean>(false);
  const [iconEmergencyHovered, setIconEmergencyHovered] = useState<boolean>(false);
  const location = useLocation();

  // Überprüfen, ob der angegebene Pfad mit der aktuellen URL übereinstimmt
  const isLinkActive = (...paths: string[]) => paths.some((path) => location.pathname === path);

  const background = 'linear-gradient(to bottom right, #f06292, #f9a76a)';
  const color = '#FFFFFF';
  const gradient = 'url(#grad3)';

  const handleHover = (icon: string, value: boolean) => {
    switch (icon) {
      case 'journal':
        setIconJournalHovered(value);
        break;
      case 'chat':
        setIconChatHovered(value);
        break;
      case 'info':
        setIconInfoHovered(value);
        break;
      case 'emergency':
        setIconEmergencyHovered(value);
        break;
      default:
        break;
    }
  };

  return (
    <nav className='navbar'>
      <ul>
        <li>
          <Link
            to='/journal'
            style={{
              background: isLinkActive(
                '/journal',
                '/journal/dayplan',
                '/journal/review',
                '/journal/pastentries',
              )
                ? background
                : '',
            }}
            className='navbar-link waves-effect waves-light hoverable'
            onMouseEnter={() => handleHover('journal', true)}
            onMouseLeave={() => handleHover('journal', false)}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='icon'>
              <defs>
                <linearGradient id='grad3' x1='0%' y1='0%' x2='100%' y2='100%'>
                  <stop offset='0%' stopColor='#f06292' />
                  <stop offset='100%' stopColor='#f9a76a' />
                </linearGradient>
              </defs>
              <path
                d='M7,3H16C17.66,3 19,4.34 19,6V19C19,20.66 17.66,22 16,22H7C5.34,22 4,20.66 4,19V6C4,4.34 5.34,3 7,3M7,4C5.9,4 5,4.9 5,6V19C5,20.1 5.9,21 7,21H16C17.1,21 18,20.1 18,19V6C18,4.9 17.1,4 16,4H13V10.7L10,8.6L7,10.7V4M12,4H8V8.78L10,7.38L12,8.78V4Z'
                fill={
                  isLinkActive(
                    '/journal',
                    '/journal/dayplan',
                    '/journal/review',
                    '/journal/pastentries',
                  ) || iconJournalHovered
                    ? color
                    : gradient
                }
              />
            </svg>
          </Link>
        </li>
        <li>
          <Link
            to='/chat'
            style={{ background: isLinkActive('/chat') ? background : '' }}
            className='navbar-link waves-effect waves-light hoverable'
            onMouseEnter={() => handleHover('chat', true)}
            onMouseLeave={() => handleHover('chat', false)}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='icon'>
              <defs>
                <linearGradient id='grad3' x1='0%' y1='0%' x2='100%' y2='100%'>
                  <stop offset='0%' stopColor='#f06292' />
                  <stop offset='100%' stopColor='#f9a76a' />
                </linearGradient>
              </defs>
              <path
                d='M3,20.59L6.59,17H18C19.1,17 20,16.1 20,15V6C20,4.9 19.1,4 18,4H5C3.9,4 3,4.9 3,6V20.59M3,22H2V6C2,4.34 3.34,3 5,3H18C19.66,3 21,4.34 21,6V15C21,16.66 19.66,18 18,18H7L3,22M6,7H17V8H6V7M6,10H17V11H6V10M6,13H14V14H6V13Z'
                fill={isLinkActive('/chat') || iconChatHovered ? color : gradient}
              />
            </svg>
          </Link>
        </li>
        <li>
          <Link
            to='/info'
            style={{ background: isLinkActive('/info') ? background : '' }}
            className='navbar-link waves-effect waves-light hoverable'
            onMouseEnter={() => handleHover('info', true)}
            onMouseLeave={() => handleHover('info', false)}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='icon'>
              <defs>
                <linearGradient id='grad3' x1='0%' y1='0%' x2='100%' y2='100%'>
                  <stop offset='0%' stopColor='#f06292' />
                  <stop offset='100%' stopColor='#f9a76a' />
                </linearGradient>
              </defs>
              <path
                d='M11.5,3C16.75,3 21,7.25 21,12.5C21,17.75 16.75,22 11.5,22C6.25,22 2,17.75 2,12.5C2,7.25 6.25,3 11.5,3M11.5,4C6.81,4 3,7.81 3,12.5C3,17.19 6.81,21 11.5,21C16.19,21 20,17.19 20,12.5C20,7.81 16.19,4 11.5,4M11,8V10H12V8H11M11,12V17H12V12H11Z'
                fill={isLinkActive('/info') || iconInfoHovered ? color : gradient}
              />
            </svg>
          </Link>
        </li>
        <li>
          <Link
            to='/emergency'
            style={{
              background: isLinkActive(
                '/emergency',
                '/emergency/copingstrategyplan',
                '/emergency/relaxation',
                '/emergency/contactdata',
                '/emergency/emergencydata',
                '/emergency/copingstrategydata',
              )
                ? background
                : '',
            }}
            className='navbar-link waves-effect waves-light hoverable'
            onMouseEnter={() => handleHover('emergency', true)}
            onMouseLeave={() => handleHover('emergency', false)}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='icon'>
              <defs>
                <linearGradient id='grad3' x1='0%' y1='0%' x2='100%' y2='100%'>
                  <stop offset='0%' stopColor='#f06292' />
                  <stop offset='100%' stopColor='#f9a76a' />
                </linearGradient>
              </defs>
              <path
                d='M12,4.5C12,4.22 11.78,4 11.5,4C11.22,4 11,4.22 11,4.5V6.03C8.75,6.28 7,8.18 7,10.5V16.41L5.41,18H17.59L16,16.41V10.5C16,8.18 14.25,6.28 12,6.03V4.5M11.5,3C12.33,3 13,3.67 13,4.5V5.21C15.31,5.86 17,8 17,10.5V16L20,19H3L6,16V10.5C6,8 7.69,5.86 10,5.21V4.5C10,3.67 10.67,3 11.5,3M11.5,22C10.29,22 9.28,21.14 9.05,20H10.09C10.29,20.58 10.85,21 11.5,21C12.15,21 12.71,20.58 12.91,20H13.95C13.72,21.14 12.71,22 11.5,22Z'
                fill={
                  isLinkActive(
                    '/emergency',
                    '/emergency/copingstrategyplan',
                    '/emergency/relaxation',
                    '/emergency/contactdata',
                    '/emergency/emergencydata',
                    '/emergency/copingstrategydata',
                  ) || iconEmergencyHovered
                    ? color
                    : gradient
                }
              />
            </svg>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
