import React from 'react';
import './styles/style.scss';
import 'materialize-css';
import useAuth from './helpers/useAuth';
import AppRouter from './router/AppRouter';
import Header from './components/Header';
import Navbar from './components/Navbar';
import { UserProvider } from './contexts/UserContext';
import EditProfileModal from './components/modals/EditProfileModal';

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <UserProvider>
        {isAuthenticated && <Header />}
        {isAuthenticated && <EditProfileModal />}
        <AppRouter />
        {isAuthenticated && <Navbar />}
      </UserProvider>
    </>
  );
};
export default App;
