import React from 'react';
import { EmergencyContacts } from '../../components/emergency/EmergencyContacts';
import { useNavigate } from 'react-router-dom';

const EmergencyData: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='page emergencydata'>
      <div className='content-header'>
        <h1>Notfallkontakte</h1>

        <button
          className='btn-large btn-styled waves-effect waves-light'
          onClick={() => navigate('/emergency/contactdata')}
        >
          Kontakt erfassen
        </button>
      </div>
      <EmergencyContacts />
    </div>
  );
};
export default EmergencyData;
