import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { User, signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { auth } from '../../helpers/useFirebase';
import useAuth from '../../helpers/useAuth';
import VerificationModal from '../../components/modals/VerificationModal';
import PasswordResetModal from '../../components/modals/PasswordResetModal';
import EmergencyModal from '../../components/modals/EmergencyModal';
import CookieModal from '../../components/modals/CookieModal';
import Spinner from '../../components/Spinner';
import Schriftzug from '../../assets/Schriftzug_MeaVia.svg';
import Icon from '@mdi/react';
import { mdilBell } from '@mdi/light-js';

interface LoginProps {
  callbackLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ callbackLogin }) => {
  const [isSpinnerOpen, setSpinnerOpen] = useState<boolean>(false);
  const [modalInstance, setModalInstance] = useState<M.Modal | null>(null);
  const [currentUserState, setCurrentUserState] = useState<User>();
  const {
    email,
    emailError,
    password,
    error,
    cookies,
    isAuthenticated,
    setCookie,
    setEmail,
    setPassword,
    setError,
  } = useAuth();

  // Überprüfe, ob aufgrund des Cookies isAuthenticated true ist. Falls ja, Weiterleitung zu Home
  useEffect(() => {
    if (isAuthenticated) {
      callbackLogin();
    }
  }, [isAuthenticated]);

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    // Versuche, Benutzer bei Firebase anzumelden
    try {
      setSpinnerOpen(true);
      const userCredentials = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredentials.user.emailVerified) {
        setCurrentUserState(userCredentials.user);
        setSpinnerOpen(false);
        onVerificationModalOpen();
        return;
      }
      if (cookies.cookiesAllowed) {
        // Setze Cookie (86’400s = 1 Tag gültig)
        setCookie('isAuthenticated', true, { maxAge: 86400 });
      } else {
        // Setze Cookie (3600s = 1h gültig)
        setCookie('isAuthenticated', true, { maxAge: 3600 });
      }
      setSpinnerOpen(false);
      callbackLogin();
    } catch {
      setError(true);
      console.error('Login failed');
      setSpinnerOpen(false);
    }
  };

  useEffect(() => {
    setCurrentUserState(currentUserState);
  }, [currentUserState]);

  // Modal Initialisierungen
  const passwordResetModalRef = useRef<HTMLDivElement>(null);
  const verificationModalRef = useRef<HTMLDivElement>(null);
  const emergencyModalRef = useRef<HTMLDivElement>(null);
  const cookieModalRef = useRef<HTMLDivElement>(null);

  // Gewünschtes Modal öffnen
  const onPasswordResetModalOpen = () => {
    if (passwordResetModalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(passwordResetModalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };
  const onVerificationModalOpen = () => {
    if (verificationModalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(verificationModalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };
  const onEmergencyModalOpen = () => {
    if (emergencyModalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(emergencyModalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };
  const onCookieModalOpen = () => {
    if (cookieModalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(cookieModalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };

  // Modal schliessen
  const onModalClose = () => {
    if (modalInstance) {
      modalInstance.close();
    }
  };

  // Allow Cookies
  const allowCookies = () => {
    setCookie('cookiesAllowed', true, { maxAge: 604800 });
    onModalClose();
  };

  useEffect(() => {
    if (
      cookies.cookiesAllowed === false ||
      cookies.cookiesAllowed === null ||
      cookies.cookiesAllowed === undefined
    ) {
      onCookieModalOpen();
    } else if (cookies.cookiesAllowed === true) {
      setCookie('cookiesAllowed', true, { maxAge: 604800 });
    } else {
      return;
    }
  }, []);

  return (
    <div className='login'>
      <div className='formWrapper'>
        <div className='logo'>
          <img src={Schriftzug} alt='MeaViaLogo' className='meavialogo' />
        </div>
        <div className='title'>Anmeldung</div>
        <form className='form-styled'>
          <div className='input-field'>
            <input
              id='email'
              type='email'
              onChange={handleEmailChange}
              onBlur={handleEmailChange}
            />
            <label htmlFor='email'>E-Mail *</label>
            {emailError && <span className='validation'>{emailError}</span>}
          </div>
          <div className='input-field'>
            <input
              id='password'
              type='password'
              onChange={handlePasswordChange}
              onBlur={handlePasswordChange}
            />
            <label htmlFor='password'>Passwort *</label>
            {!password && <span className='validation'>Passwort darf nicht leer sein.</span>}
          </div>

          <button
            className={`btn-large btn-styled waves-effect waves-light ${
              emailError || !password ? 'disabled' : ''
            }`}
            onClick={handleLogin}
          >
            Anmelden
          </button>
          {error && <span className='validation'>Benutzername oder Passwort ist falsch!</span>}
        </form>
        <p>
          Passwort vergessen? <a onClick={onPasswordResetModalOpen}>Passwort zurücksetzen</a>
        </p>
        <p>
          Noch kein Konto? <Link to='/register'>Registrierung</Link>
        </p>
        <a
          className='btn-floating btn-large waves-effect waves-light valign-wrapper'
          onClick={onEmergencyModalOpen}
        >
          <Icon path={mdilBell} size={1} className='icon' />
        </a>
      </div>
      <PasswordResetModal
        id='passwordResetModal'
        ref={passwordResetModalRef}
        onClose={onModalClose}
      />
      <VerificationModal
        id='verificationModal'
        ref={verificationModalRef}
        isFirstCall={false}
        user={currentUserState}
        onClose={onModalClose}
      />
      <EmergencyModal id='emergencyModal' ref={emergencyModalRef} onClose={onModalClose} />
      <CookieModal
        id='cookieModal'
        ref={cookieModalRef}
        onClose={onModalClose}
        onAccept={allowCookies}
      />
      <Spinner isOpen={isSpinnerOpen} />
    </div>
  );
};

export default Login;
