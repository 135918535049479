// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Firebase Konfiguration
const firebaseConfig = {
  apiKey: 'AIzaSyA_3Cc9bQBTljZLH4hp51YOYRAtBM188fk',
  authDomain: 'meavia-288c6.firebaseapp.com',
  projectId: 'meavia-288c6',
  storageBucket: 'meavia-288c6.appspot.com',
  messagingSenderId: '390978059040',
  appId: '1:390978059040:web:2ce1d2279877638821f545',
  measurementId: 'G-7ZKQ8EXK61',
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export default app;
