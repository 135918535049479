import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useUser } from '../../contexts/UserContext';
import useDbContact from '../../helpers/database/useDbContact';
import showToast from '../../components/Toast';

export type Contact = {
  contactId: string;
  data: {
    name: string;
    relationship?: string;
    address?: string;
    phone1?: string;
    phone2?: string;
  };
};

const ContactData: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { createContact, updateContact, getContact, deleteContact } = useDbContact();
  const location = useLocation();
  const editingContactId = new URLSearchParams(location.search).get('editingContact');
  const isEditingMode = Boolean(editingContactId);
  const headerDescription = isEditingMode
    ? 'Aktualisiere oder entferne deine Notfallkontakte'
    : 'Notiere hier weitere Kontatke, welche dir in einem Notfall weiter helfen können. (z.B. Vertrauenspersonen, Hausarzt etc.)';
  const buttonText = isEditingMode ? 'Aktualisieren' : 'Speichern';
  const [isValidPhone1, setIsValidPhone1] = useState(true);
  const [isValidPhone2, setIsValidPhone2] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [contact, setContact] = useState<Contact>({
    contactId: '',
    data: {
      name: '',
      relationship: '',
      address: '',
      phone1: '',
      phone2: '',
    },
  });

  // Validierung Telefonnummer
  const isValidSwissPhoneNumber = (number: string | undefined): boolean => {
    if (number === undefined || number === '') {
      // Wenn die Telefonnummer undefined ist, betrachten wir sie als gültig.
      return true;
    }
    // Entfernen von nicht-numerischen Zeichen
    const cleaned = number.replace(/[^+\d]/g, '');

    // Prüfen auf Mobil- oder Festnetznummer
    const pattern =
      /^([0][1-9][0-9](\s|)[0-9][0-9][0-9](\s|)[0-9][0-9](\s|)[0-9][0-9])$|^(([0][0]|\+)[1-9][0-9](\s|)[0-9][0-9](\s|)[0-9][0-9][0-9](\s|)[0-9][0-9](\s|)[0-9][0-9])$/;

    return pattern.test(cleaned);
  };

  // Zu Bearbeitenden Kontakt in die Formularfelder füllen
  useEffect(() => {
    const fetchContact = async () => {
      try {
        if (user && editingContactId) {
          const fetchedContact = await getContact(user.uid, editingContactId);
          if (fetchedContact != null) {
            setContact(fetchedContact);
          }
        }
      } catch (error) {
        console.error('Es gab einen Fehler beim Abrufen des Kontakts:', error);
      }
    };

    fetchContact();
  }, [editingContactId]);

  // Änderungen Formular in setContact Hook speichern
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = event.target;

    // Telefonnummer-Validierung durchführen
    if (id === 'phone1') {
      setIsValidPhone1(isValidSwissPhoneNumber(value));
    } else if (id === 'phone2') {
      setIsValidPhone2(isValidSwissPhoneNumber(value));
    }

    setContact((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [id]: value, // Aktualisiere das verschachtelte Objekt "encryptedContact"
      },
    }));
  };

  // Kontakt Hinzufügen/Aktualisieren
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Kontakt aktualisieren
    if (isEditingMode) {
      if (user?.uid) {
        if (
          !isValidSwissPhoneNumber(contact.data.phone1) ||
          !isValidSwissPhoneNumber(contact.data.phone2)
        ) {
          showToast('Bitte geben Sie eine gültige Telefonnummer ein.');
          return;
        }
        if (
          isValidSwissPhoneNumber(contact.data.phone1) &&
          isValidSwissPhoneNumber(contact.data.phone2)
        ) {
          await updateContact(user?.uid, contact);
        }
      }
    } else {
      // Kontakt hinzufügen
      const contactWithUid = { ...contact, uid: user?.uid, id: uuid() };
      if (contactWithUid.uid) {
        if (
          !isValidSwissPhoneNumber(contactWithUid.data.phone1) ||
          !isValidSwissPhoneNumber(contactWithUid.data.phone2)
        ) {
          showToast('Bitte geben Sie eine gültige Telefonnummer ein.');
          return;
        }

        if (
          isValidSwissPhoneNumber(contactWithUid.data.phone1) &&
          isValidSwissPhoneNumber(contactWithUid.data.phone2)
        ) {
          await createContact(
            contactWithUid.uid,
            contactWithUid.id,
            contactWithUid.data.name,
            contactWithUid.data.relationship,
            contactWithUid.data.address,
            contactWithUid.data.phone1,
            contactWithUid.data.phone2,
          );
        }
      }
    }
    // Felder zurücksetzen
    setContact({
      contactId: '',
      data: {
        name: '',
        relationship: '',
        address: '',
        phone1: '',
        phone2: '',
      },
    });
    navigate('/emergency/emergencydata');
    showToast(isEditingMode ? 'Kontakt aktualisiert' : 'Kontakt gespeichert');
  };

  // Kontakt löschen
  const handleDelete = async () => {
    try {
      await deleteContact(contact.contactId);
      navigate('/emergency/emergencydata');
      showToast('Kontakt gelöscht');
    } catch (error) {
      console.error('Contact could not be deleted: ' + error);
    }
  };
  return (
    <div className='page'>
      <h1>{isEditingMode ? 'Kontakt bearbeiten' : 'Kontakt erfassen'}</h1>
      <p>{headerDescription}</p>
      <form className='form-styled'>
        <div className='input-field'>
          <input
            id='name'
            type='text'
            value={contact.data.name || ''}
            onChange={handleChange}
            required
          />
          <label htmlFor='name' className={`${contact.data.name ? 'active' : ''}`}>
            Name
          </label>
        </div>
        <div className='input-field'>
          <input
            id='relationship'
            type='text'
            value={contact.data.relationship || ''}
            onChange={handleChange}
          />
          <label htmlFor='relationship' className={`${contact.data.relationship ? 'active' : ''}`}>
            Beziehung
          </label>
        </div>
        <div className='input-field'>
          <input
            id='address'
            type='text'
            value={contact.data.address || ''}
            onChange={handleChange}
          />
          <label htmlFor='address' className={`${contact.data.address ? 'active' : ''}`}>
            Adresse
          </label>
        </div>
        <div className='input-field'>
          <input
            id='phone1'
            type='text'
            value={contact.data.phone1 || ''}
            onChange={handleChange}
          />
          <label htmlFor='phone1' className={`${contact.data.phone1 ? 'active' : ''}`}>
            Telefonnummer Privat
          </label>
          {!isValidPhone1 && (
            <div className='validation' onClick={() => setShowTooltip(!showTooltip)}>
              Gültiges Telefonformat erforderlich.
              <br />
              {showTooltip && (
                <span className='tooltiptext'>
                  CH-Formate: 07x xxx xx xx / 041 xxx xx xx / 0041 xx xxx xx xx / +41 xx xxx xx xx
                </span>
              )}
            </div>
          )}
        </div>
        <div className='input-field'>
          <input
            id='phone2'
            type='text'
            value={contact.data.phone2 || ''}
            onChange={handleChange}
          />
          <label htmlFor='phone2' className={`${contact.data.phone2 ? 'active' : ''}`}>
            Telefonnummer Geschäft
          </label>
          {!isValidPhone2 && (
            <div className='validation' onClick={() => setShowTooltip(!showTooltip)}>
              Gültiges Telefonformat erforderlich.
              <br />
              {showTooltip && (
                <span className='tooltiptext'>
                  CH-Formate: 07x xxx xx xx / 041 xxx xx xx / 0041 xx xxx xx xx / +41 xx xxx xx xx
                </span>
              )}
            </div>
          )}
        </div>

        <div className='btn-container'>
          <div className='btn-group'>
            <button
              className='btn-large btn-styled waves-effect waves-light'
              type='submit'
              onClick={handleSubmit}
            >
              {buttonText}
            </button>
            <button
              className='btn-large btn-styled waves-effect waves-light'
              type='button'
              onClick={() => navigate('/emergency/emergencydata')}
            >
              Abbrechen
            </button>
            {isEditingMode && (
              <button
                className='btn-large btn-styled waves-effect waves-light btn-red'
                type='button'
                onClick={handleDelete}
              >
                Kontakt löschen
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default ContactData;
