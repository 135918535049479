import { deleteField, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../useFirebase';
import useE2EE from '../useE2EE';
import { JournalData } from '../useJournal';

const useDbJournal = () => {
  const { encryptData, decryptData } = useE2EE();

  // Erstelle für den Benutzer ein neues journal-Dokument in der DB
  const createJournal = async (uid: string) => {
    try {
      await setDoc(doc(db, 'journal', uid), {});
    } catch (error) {
      console.error('Journal could not be created: ', error);
    }
  };

  // Erstelle/Update Dayplan-Eintrag in der DB
  const updateDayplan = async (uid: string, date: Date, journalData: string) => {
    try {
      // Überprüfung, ob Journalbereich vorhanden. Wenn nicht, wird dieser erstellt.
      const journalDoc = await getDoc(doc(db, 'journal', uid));
      if (!journalDoc.exists()) {
        await createJournal(uid);
      }

      // Verschlüssle Dayplan
      const encryptedDayplan = await encryptData(journalData);

      // Erstelle/Aktualisiere Dayplan-Eintrag
      await setDoc(
        doc(db, 'journal', uid),
        {
          [`${date}`]: {
            encryptedDayplan: encryptedDayplan,
          },
        },
        { merge: true },
      );
    } catch (error) {
      console.error('Update of dayplan failed: ', error);
    }
  };

  // Erstelle/Aktualisiere Review-Eintrag in der DB
  const updateReview = async (uid: string, date: Date, journalData: string) => {
    try {
      // Überprüfung, ob Journalbereich vorhanden. Wenn nicht, wird dieser erstellt.
      const journalDoc = await getDoc(doc(db, 'journal', uid));
      if (!journalDoc.exists()) {
        await createJournal(uid);
      }

      // Verschlüssle Review
      const encryptedReview = await encryptData(journalData);

      // Erstelle/Update Review-Eintrag
      await setDoc(
        doc(db, 'journal', uid),
        {
          [`${date}`]: {
            encryptedReview: encryptedReview,
          },
        },
        { merge: true },
      );
    } catch (error) {
      console.error('Update of review failed: ', error);
    }
  };

  // Lösche Dayplan-Eintrag aus der DB
  const deleteDayplan = async (uid: string, date: Date) => {
    try {
      const docRef = doc(db, 'journal', uid);
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();

      if (data && data[`${date}`] && data[`${date}`].encryptedReview) {
        // Falls encryptedReview existiert, lösche nur encryptedDayplan
        await updateDoc(docRef, {
          [`${date}.encryptedDayplan`]: deleteField(),
        });
      } else {
        // Falls encryptedReview nicht existiert, lösche den gesamten Eintrag für das Datum
        await updateDoc(docRef, {
          [`${date}`]: deleteField(),
        });
      }
    } catch (error) {
      console.error('Dayplan could not be deleted: ', error);
    }
  };

  // Lösche Review-Eintrag aus der DB
  const deleteReview = async (uid: string, date: Date) => {
    try {
      const docRef = doc(db, 'journal', uid);
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();

      if (data && data[`${date}`] && data[`${date}`].encryptedDayplan) {
        // Falls encryptedDayplan existiert, lösche nur encryptedReview
        await updateDoc(docRef, {
          [`${date}.encryptedReview`]: deleteField(),
        });
      } else {
        // Falls encryptedDayplan nicht existiert, lösche den gesamten Eintrag für das Datum
        await updateDoc(docRef, {
          [`${date}`]: deleteField(),
        });
      }
    } catch (error) {
      console.error('Review could not be deleted: ', error);
    }
  };

  // Suche nach Dayplan-Eintrag im Journal und gebe diesen zurück
  // Gibt Dayplan als String zurück
  const getDayplan = async (uid: string, date: Date): Promise<string> => {
    try {
      const journalDoc = await getDoc(doc(db, 'journal', uid));

      // Überprüfung, ob Journalbereich vorhanden ist
      if (journalDoc.exists()) {
        const journalData = journalDoc.data();

        // Überprüfung, ob für ausgewählten Tag ein Dayplan vorhanden ist
        if (journalData[`${date}`] && journalData[`${date}`].encryptedDayplan) {
          // Entschlüssle Dayplan
          const decryptedDayplan = await decryptData(journalData[`${date}`].encryptedDayplan);
          return decryptedDayplan;
        }
      }
      return '';
    } catch (error) {
      console.error('Dayplan data could not be retrieved: ', error);
      return '';
    }
  };

  // Suche nach Review-Eintrag im Journal und gebe diesen zurück
  // Gibt Review als String zurück
  const getReview = async (uid: string, date: Date): Promise<string> => {
    try {
      const journalDoc = await getDoc(doc(db, 'journal', uid));

      // Überprüfung, ob Journalbereich vorhanden ist
      if (journalDoc.exists()) {
        const journalData = journalDoc.data();

        // Überprüfung, ob für ausgewählten Tag ein Review vorhanden ist
        if (journalData[`${date}`] && journalData[`${date}`].encryptedReview) {
          // Entschlüssle Review
          const decryptedReview = await decryptData(journalData[`${date}`].encryptedReview);
          return decryptedReview;
        }
      }
      return '';
    } catch (error) {
      console.error('Review data could not be retrieved: ', error);
      return '';
    }
  };

  const getAllDayplans = async (uid: string): Promise<JournalData[]> => {
    try {
      const journalDoc = await getDoc(doc(db, 'journal', uid));

      // Überprüfung, ob Journalbereich vorhanden ist
      if (journalDoc.exists()) {
        const journalData = journalDoc.data();

        // Initialisiere ein Array, um die 'encryptedReview' Werte zu speichern.
        const decryptedDayplans: JournalData[] = [];

        // Iteriere durch die Keys in 'journalData'
        for (const key in journalData) {
          if (
            Object.prototype.hasOwnProperty.call(journalData, key) &&
            journalData[key].encryptedDayplan
          ) {
            const decryptedDayplan = await decryptData(journalData[key].encryptedDayplan);
            // Wenn der Schlüssel die Eigenschaft 'encryptedReview' besitzt, füge ihn dem Array hinzu.
            decryptedDayplans.push(JSON.parse(decryptedDayplan));
          }
        }

        // Gibt ein Array von 'encryptedReview' Werten zurück
        return decryptedDayplans;
      }
      return [];
    } catch (error) {
      console.error('Dayplan data could not be retrieved: ', error);
      return [];
    }
  };

  const getAllReviews = async (uid: string): Promise<JournalData[]> => {
    try {
      const journalDoc = await getDoc(doc(db, 'journal', uid));

      // Überprüfung, ob Journalbereich vorhanden ist
      if (journalDoc.exists()) {
        const journalData = journalDoc.data();

        // Initialisiere ein Array, um die 'encryptedReview' Werte zu speichern.
        const decryptedReviews: JournalData[] = [];

        // Iteriere durch die Keys in 'journalData'
        for (const key in journalData) {
          if (
            Object.prototype.hasOwnProperty.call(journalData, key) &&
            journalData[key].encryptedReview
          ) {
            const decryptedReview = await decryptData(journalData[key].encryptedReview);
            // Wenn der Schlüssel die Eigenschaft 'encryptedReview' hat, füge ihn dem Array hinzu.
            decryptedReviews.push(JSON.parse(decryptedReview));
          }
        }

        // Gib das Array von verschlüsselten 'encryptedReview' Werten zurück.
        return decryptedReviews;
      }
      return [];
    } catch (error) {
      console.error('Review data could not be retrieved: ', error);
      return [];
    }
  };

  // Gibt alle Einträge des Journals zurück
  const getAllEntries = async (
    uid: string,
  ): Promise<Record<string, { dayplan: string; review: string }>> => {
    try {
      const journalDoc = await getDoc(doc(db, 'journal', uid));
      if (journalDoc.exists()) {
        const rawData = journalDoc.data();
        const decryptedData: Record<string, { dayplan: string; review: string }> = {};

        for (const [date, entry] of Object.entries(rawData)) {
          const decryptedEntry: { dayplan: string; review: string } = {
            dayplan: '',
            review: '',
          };

          if (entry.encryptedDayplan) {
            decryptedEntry.dayplan = await decryptData(entry.encryptedDayplan);
          }

          if (entry.encryptedReview) {
            decryptedEntry.review = await decryptData(entry.encryptedReview);
          }

          decryptedData[date] = decryptedEntry;
        }
        return decryptedData;
      }
      return {};
    } catch (error) {
      console.error('Failed to retrieve all entries: ', error);
      return {};
    }
  };

  return {
    createJournal,
    updateDayplan,
    updateReview,
    deleteDayplan,
    deleteReview,
    getDayplan,
    getReview,
    getAllDayplans,
    getAllReviews,
    getAllEntries,
  };
};

export default useDbJournal;
