import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { Contact } from '../../pages/emergency/ContactData';
import useDbContact from '../../helpers/database/useDbContact';
import Icon from '@mdi/react';
import { mdilPencil } from '@mdi/light-js';

export const EmergencyContacts: React.FC = () => {
  const [userContatcs, setUserContacts] = useState<Contact[]>([]);
  const { getAllContacts } = useDbContact();
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const data = await getAllContacts(user.uid);
        setUserContacts(data);
      };
      fetchData();
    }
  }, []);

  // Entfernt Leerstellen aus der Telefonnummer
  const cleanPhoneNumber = (number: string) => number.replace(/\s/g, '');

  // Kontakt editieren
  const handleEditClick = (contact: Contact) => {
    navigate(`/emergency/contactdata?editingContact=${contact.contactId}`);
  };

  return (
    <div className='emergencycontacts'>
      <ul className='emergency-list'>
        {/*Hard coded emergency contacts*/}
        <li className='emergency-item'>
          <h2>Dargebotene Hand</h2>
          <p className='emergency-details'>
            Telefonische Hilfe
            <br /> rund um die Uhr
          </p>
          <a href='tel:143' className='emergency-number'>
            143
          </a>
        </li>
        <li className='emergency-item'>
          <h2>Allgemeiner Notruf</h2>
          <p className='emergency-details'>
            Notrufzentrale und Hilfe <br />
            rund um die Uhr
          </p>
          <a href='tel:112' className='emergency-number'>
            112
          </a>
        </li>
        <li className='emergency-item'>
          <h2>Ambulanz</h2>
          <p className='emergency-details'>
            Medizinische Hilfe <br />
            rund um die Uhr
          </p>
          <a href='tel:144' className='emergency-number'>
            144
          </a>
        </li>
        <li className='emergency-item'>
          <h2>Polizei</h2>
          <p className='emergency-details'>Bei akuter Selbst- und/oder Fremdgefährdung</p>
          <a href='tel:117' className='emergency-number'>
            117
          </a>
        </li>
        <li className='emergency-item'>
          <h2>Angst- und Panikhilfe Schweiz</h2>
          <p className='emergency-details'>
            Telefonische Hilfe <br />
            rund um die Uhr
          </p>
          <a href='tel:0848801109' className='emergency-number'>
            0848 801 109
          </a>
        </li>
        <li className='emergency-item'>
          <h2>Pro Mente Sana</h2>
          <p className='emergency-details'>
            Beratungshotline <br /> (Montag bis Freitag)
          </p>
          <a href='tel:0848800858' className='emergency-number'>
            0848 800 858
          </a>
        </li>

        {/*Database emergency contacts*/}
        {userContatcs.map((contact) => (
          <li className='emergency-item' key={contact.contactId}>
            <h2>
              <span>{contact.data.name}</span>
              <div className='options' onClick={() => handleEditClick(contact)}>
                <Icon path={mdilPencil} size={1} />
              </div>
            </h2>
            <div className='emergency-details'>
              {contact.data.relationship && <p>{contact.data.relationship}</p>}
              {contact.data.address && <p>{contact.data.address}</p>}
            </div>
            {contact.data.phone1 && (
              <a href={`tel:${cleanPhoneNumber(contact.data.phone1)}`} className='emergency-number'>
                {contact.data.phone1}
              </a>
            )}
            {contact.data.phone2 && (
              <a href={`tel:${cleanPhoneNumber(contact.data.phone2)}`} className='emergency-number'>
                {contact.data.phone2}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
