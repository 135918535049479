import React, { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import useAuth from '../../helpers/useAuth';
import useDbImage from '../../helpers/database/useDbImage';
import { UserContext } from '../../contexts/UserContext';
import useDbUser from '../../helpers/database/useDbUser';
import Icon from '@mdi/react';
import { mdilImage } from '@mdi/light-js';
import showToast from '../Toast';
import Spinner from '../Spinner';

const EditProfileModal: React.FC = () => {
  const { uploadImageAvatar } = useDbImage();
  const { getUser, updateUser } = useDbUser();
  const [isSpinnerOpen, setSpinnerOpen] = useState<boolean>(false);
  const { displayName, displayNameError, avatar, avatarError, setDisplayName, setAvatar } =
    useAuth();
  const { user: currentUser } = useContext(UserContext);

  // Initialisiere Modal
  useEffect(() => {
    const modalElement = document.querySelectorAll('.modal');
    if (modalElement) {
      M.Modal.init(modalElement, {});
    }
  }, []);

  // Lade Userdaten in Formularfelder
  useEffect(() => {
    if (currentUser) {
      getUser(currentUser.uid)
        .then((entry) => {
          if (entry) {
            setDisplayName(entry.displayName);
          }
        })
        .catch((error) => {
          console.error('Error loading user data', error);
        });
    }
  }, [currentUser]);

  const handleDisplayNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(e.target.value);
  }, []);

  const handleAvatarChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAvatar(e.target.files[0]);
    }
  }, []);

  const handleCancel = async (e: FormEvent) => {
    e.preventDefault();
    closeModal();
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (currentUser) {
        setSpinnerOpen(true);
        // Falls Avatar geändert wurde, wird Bild in Storage hochgeladen
        let photoURL: string | undefined;

        if (avatar && avatarError == '') {
          photoURL = await uploadImageAvatar(avatar, currentUser.uid);
        }

        // Userdaten aktualisieren
        await updateUser(displayName, photoURL);
        currentUser.displayName = displayName;
        currentUser.photoURL = photoURL ? photoURL : currentUser.photoURL;

        showToast('Profil aktualisiert');
        setSpinnerOpen(false);
      } else {
        showToast('Benutzerdaten konnten nicht gelesen werden');
        setSpinnerOpen(false);
      }
    } catch {
      console.error('Profile change failed');
      showToast('Fehler bei Aktualisierung des Profils');
      setSpinnerOpen(false);
    }
    closeModal();
    closeSidenav();
  };

  const isError = () => {
    return displayName === ' ' || displayNameError || avatarError;
  };

  const closeModal = () => {
    const modalElement = document.querySelector('.modal');
    if (modalElement) {
      const instance = M.Modal.getInstance(modalElement);
      instance.close();
    }
  };

  const closeSidenav = () => {
    const sideNavElement = document.querySelector('.sidenav');
    if (sideNavElement) {
      const instance = M.Sidenav.getInstance(sideNavElement);
      instance.close();
    }
  };

  return (
    <div id='editProfileModal' className='modal modal-styled modal-small'>
      <div className='modal-title'>Profil bearbeiten</div>
      <div className='modal-content formWrapper'>
        <form className='form-styled'>
          <div className='input-field'>
            <input
              id='displayname'
              type='text'
              value={displayName}
              onChange={handleDisplayNameChange}
              onBlur={handleDisplayNameChange}
            />
            <label htmlFor='displayname' className={`${displayName ? 'active' : ''}`}>
              Anzeigename *
            </label>
            {displayNameError && <span className='validation'>{displayNameError}</span>}
          </div>
          <div className='file-field input-field avatar-container'>
            <div className='avatar-upload'>
              <Icon className='icon' path={mdilImage} />
              <input id='avatarimage' type='file' onChange={handleAvatarChange} />
            </div>
            <div className='file-path-wrapper'>
              <input
                id='avatarimage-path'
                className='file-path'
                type='text'
                placeholder='Avatar hochladen'
              />
            </div>
            {avatarError && <span className='validation'>{avatarError}</span>}
          </div>
        </form>
      </div>
      <div className='modal-footer'>
        <button className='waves-effect waves-red btn-flat' onClick={handleCancel}>
          Abbrechen
        </button>
        <button
          className={`waves-effect waves-green btn-flat ${isError() ? 'disabled' : ''}`}
          onClick={handleSubmit}
        >
          Speichern
        </button>
      </div>
      <Spinner isOpen={isSpinnerOpen} />
    </div>
  );
};

export default EditProfileModal;
