import { storage } from '../useFirebase';
import { ref, listAll, getDownloadURL } from 'firebase/storage';

export interface MusicItem {
  title: string;
  artist: string;
  url: string;
}

const useDbMusic = () => {
  // Holt aus dem Musikordner alle Daten
  // Daten zu MusicItem umformen und als Array zurück geben
  const getAllMusic = async (): Promise<MusicItem[]> => {
    const storageRef = ref(storage, 'Relaxation Music');
    const result = await listAll(storageRef);
    const musicItems: MusicItem[] = [];

    for (const musicRef of result.items) {
      const url = await getDownloadURL(musicRef);
      const [titleWithHyphens, artistWithHypens] = musicRef.name.split('_');
      const title = titleWithHyphens.replace(/-/g, ' ').split('.')[0];
      const artist = artistWithHypens.replace(/-/g, ' ').split('.')[0];
      musicItems.push({
        title,
        artist,
        url,
      });
    }

    return musicItems;
  };

  return { getAllMusic };
};

export default useDbMusic;
