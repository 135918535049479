import React, { useContext, useEffect, useState } from 'react';
import { FirebaseUser, UserContext } from '../../contexts/UserContext';
import useDbChat from '../../helpers/database/useDbChat';
import useDbUser from '../../helpers/database/useDbUser';

const Search: React.FC = () => {
  const { user: currentUser } = useContext(UserContext);
  const { createOrRetrieveChat } = useDbChat();
  const { getUsers } = useDbUser();
  const [username, setUsername] = useState<string>('');
  const [searchResults, setSearchResults] = useState<FirebaseUser[]>([]);

  useEffect(() => {
    setUsername(username);
    handleSearch();
  }, [username]);

  const handleSearch = async () => {
    if (username.length >= 3) {
      const data = await getUsers(username);
      setSearchResults(data);
    } else {
      setSearchResults([]);
    }
  };

  // Suche starten mit 'Enter'
  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.code === 'Enter' && handleSearch();
  };

  const handleSelect = async (selectedUser: FirebaseUser) => {
    // Überprüfe, ob bereits ein gemeinsamer Chat existiert, sonst einen gemeinsamen Chat erstellen.
    if (!currentUser?.uid || !selectedUser?.uid) {
      return;
    }

    try {
      await createOrRetrieveChat(currentUser.uid, selectedUser.uid);
    } catch (error) {
      console.error(error);
    }

    setUsername('');
    setSearchResults([]);
  };

  return (
    <div className='search'>
      <div className='searchFormInput'>
        <input
          type='text'
          placeholder='Suchen...'
          onKeyDown={handleKey}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
      </div>

      {searchResults.length > 0 && (
        <ul>
          {searchResults.map((result, index) => (
            <li
              className='userChat'
              key={index}
              onClick={async () => {
                await handleSelect(result);
              }}
            >
              {result.photoURL && <img src={result.photoURL} alt='User' />}
              {result.displayName && <span>{result.displayName}</span>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;
