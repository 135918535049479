import E2EE from '@chatereum/react-e2ee'; // https://github.com/Arjis2020/react-e2ee
import { decode as base64_decode } from 'base-64';

const useE2EE = () => {
  // const encoded = base64_encode('YOUR_DECODED_STRING');
  // const decoded = base64_decode('YOUR_ENCODED_STRING');

  // Lese den PrivateKey aus den Umgebungsvariablen
  const getPrivateKey = () => {
    if (process.env.REACT_APP_PRIVATE_KEY) {
      const privateKey = base64_decode(process.env.REACT_APP_PRIVATE_KEY);
      return privateKey;
    }
    return '';
  };

  // Lese den PublicKey aus den Umgebungsvariablen
  const getPublicKey = () => {
    if (process.env.REACT_APP_PUBLIC_KEY) {
      const publicKey = base64_decode(process.env.REACT_APP_PUBLIC_KEY);
      return publicKey;
    }
    return '';
  };

  // Daten werden mit Publickey verschlüsselt
  // Gibt verschlüsselte Daten als String zurück
  const encryptData = async (data: string): Promise<string> => {
    try {
      const encryptedData = await E2EE.encrypt(getPublicKey(), data);
      return JSON.stringify(encryptedData);
    } catch (error) {
      console.error('Encryption failed: ', error);
      return '';
    }
  };

  // Daten werden mit PrivateKey entschlüsselt
  // Gibt entschlüsselte Daten als String zurück
  const decryptData = async (encryptedData: string): Promise<string> => {
    const encryptedObject = JSON.parse(encryptedData);

    try {
      const decryptedData = await E2EE.decrypt(
        encryptedObject.aes_key,
        encryptedObject.iv,
        getPrivateKey(),
        encryptedObject.cipher_text,
      );
      return decryptedData;
    } catch (error) {
      console.error('Decryption failed: ', error);
      return '';
    }
  };

  return { encryptData, decryptData };
};

export default useE2EE;
