import React, { useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import BlogModal from '../modals/BlogModal';
import { mdilArrowRight } from '@mdi/light-js';
import Icon from '@mdi/react';
import Default_Illustration_animated from '../../assets/Default_Illustration_animated.svg';
import Default_Illustration_static from '../../assets/Default_Illustration_static.svg';

interface CardProps {
  data: {
    title: string;
    leadtext: string;
    content: string;
    colorScheme: string;
    imageStatic: string;
    imageAnimated: string;
  };
}

const Card: React.FC<CardProps> = ({ data }) => {
  const [modalInstance, setModalInstance] = useState<M.Modal | null>(null);

  const { ref, inView } = useInView({
    threshold: 1,
  });

  // Modal Initialisierungen
  const modalRef = useRef<HTMLDivElement>(null);

  // Modal öffnen, um Blog-Eintrag zu lesen
  const onModalOpen = () => {
    if (modalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(modalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };

  // Modal schliessen
  const onModalClose = () => {
    if (modalInstance) {
      modalInstance.close();
    }
  };

  return (
    <div className='card' ref={ref}>
      <div className='card-image'>
        {data.imageStatic ? (
          inView ? (
            <img src={data.imageAnimated} alt='Illustration' />
          ) : (
            <img src={data.imageStatic} alt='Illustration' />
          )
        ) : inView ? (
          <img src={Default_Illustration_animated} alt='Illustration' />
        ) : (
          <img src={Default_Illustration_static} alt='Illustration' />
        )}
        <span
          className='card-title'
          style={{
            backgroundColor: data.colorScheme,
            color: data.colorScheme === '#ffd54f' ? '#262626' : '',
          }}
        >
          {data.title}
        </span>
        <a
          className='btn-floating halfway-fab waves-effect waves-light'
          style={{
            backgroundColor: data.colorScheme,
            color: data.colorScheme === '#ffd54f' ? '#262626' : '',
          }}
          onClick={onModalOpen}
        >
          <Icon className='icon' path={mdilArrowRight} size={1} />
        </a>
      </div>
      <div className='card-content'>
        <p>{data.leadtext}</p>
      </div>
      <BlogModal id='blogModal' ref={modalRef} data={data} onClose={onModalClose} />
    </div>
  );
};

export default Card;
