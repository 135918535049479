import React, { forwardRef } from 'react';

interface AgbProps {
  id: string;
  onClose: () => void;
}

const AgbModal = forwardRef<HTMLDivElement, AgbProps>((props, ref) => {
  return (
    <div ref={ref} className='modal modal-styled agb-modal'>
      <div className='modal-title'>AGBs f&uuml;r MeaVia</div>
      <div className='modal-content'>
        <p>
          Letzte Aktualisierung: 01.11.2023
          <br />
          <br />
          Willkommen bei MeaVia! Bitte lesen Sie diese Allgemeinen Gesch&auml;ftsbedingungen
          sorgf&auml;ltig durch, bevor Sie unsere App nutzen. Durch die Nutzung unserer Dienste
          erkl&auml;ren Sie sich mit diesen Bedingungen einverstanden.
        </p>
        <h2>Geltungsbereich</h2>
        <p>
          1.1 Diese Allgemeinen Gesch&auml;ftsbedingungen (im Folgenden &quot;AGB&quot;) regeln die
          Nutzung der App MeaVia (im Folgenden &quot;die App&quot;) und alle damit verbundenen
          Dienstleistungen.
          <br />
          1.2 Die App ist eine kostenlose Anwendung, die von der Mentesana Klinik (nachfolgend
          &quot;Wir&quot; genannt) betrieben wird.
          <br />
          1.3 Die Nutzung der App unterliegt der Zustimmung und Einhaltung dieser AGB. Mit der
          Registrierung und Nutzung der App erkl&auml;ren Sie sich mit diesen AGB einverstanden.
        </p>
        <h2>Registrierung und Nutzerkonto</h2>
        <p>
          2.1 Die Nutzung der App erfordert die Registrierung eines pers&ouml;nlichen Nutzerkontos.
          Bei der Registrierung sind Sie verpflichtet, eine g&uuml;ltige E-Mail-Adresse und ein
          sicheres Passwort anzugeben.
          <br />
          2.2 Sie sind allein verantwortlich f&uuml;r die Vertraulichkeit Ihres Passworts und Ihres
          Nutzerkontos. Sie d&uuml;rfen Ihr Nutzerkonto nicht an Dritte weitergeben.
          <br />
          2.3 Sie haben die M&ouml;glichkeit, einen freiwilligen Anzeigenamen und ein Avatar-Bild
          hochzuladen. Diese Informationen sind optional und unterliegen den Datenschutzbestimmungen
          (siehe Abschnitt 4).
        </p>
        <h2>Nutzung der App</h2>
        <p>
          3.1 Die App richtet sich an Personen mit depressiven Symptomen, die sich registrieren und
          anmelden k&ouml;nnen, um die in der App verf&uuml;gbaren Funktionen zu nutzen.
          <br />
          3.2 <span style={{ fontWeight: '500' }}>Wichtig:</span> Die App ist als erg&auml;nzendes
          Hilfsmittel konzipiert und soll in keinem Fall professionelle medizinische oder
          therapeutische Hilfe ersetzen. Bei anhaltender Symptomatik, Verschlechterung oder im
          Notfall wenden Sie sich bitte an Ihren Arzt oder eine andere medizinische Fachperson.
          <br />
          3.3 Die App bietet unter anderem folgende Funktionen:
          <br />
          Notfallbereich: Sie k&ouml;nnen Notfallkontakte einsehen und zus&auml;tzlich Ihre eigenen
          Notfallkontakte erfassen.
          <br />
          Bew&auml;ltigungsstrategieplan: Sie k&ouml;nnen einen Bew&auml;ltigungsstrategieplan
          erstellen und zu jeder Zeit einsehen.
          <br />
          Tagebuch: Sie k&ouml;nnen Tagesziele, Ihre Stimmung und Tagesr&uuml;ckblick erfassen.
          <br />
          Chat: Sie k&ouml;nnen sich mit anderen Personen austauschen.
          <br />
          Info-Bereich: Sie k&ouml;nnen allgemeine Informationen zu Therapiem&ouml;glichkeiten,
          Events, Tipps zu gesundem Leben und weitere einsehen.
          <br />
          3.4 Die Nutzung der App erfolgt auf eigene Gefahr. Wir &uuml;bernehmen keine Haftung
          f&uuml;r Sch&auml;den, die durch die Nutzung der App entstehen.
          <br />
          3.5 Sie verpflichten sich, die App nur in &Uuml;bereinstimmung mit geltenden Gesetzen und
          Vorschriften sowie ethischen Standards zu nutzen.
        </p>
        <h2>Datenschutz und Datensicherheit</h2>
        <p>
          4.1 Ihre pers&ouml;nlichen Daten werden mit h&ouml;chster Vertraulichkeit behandelt und
          nicht an Dritte weitergegeben oder f&uuml;r private Zwecke verwendet.
          <br />
          4.2 Die in der App erfassten Daten werden verschl&uuml;sselt &uuml;bertragen und in einer
          gesicherten Datenbank (Firebase) gespeichert.
          <br />
          4.3 Die Daten&uuml;bertragung erfolgt zu jeder Zeit &uuml;ber HTTPS.
        </p>
          <h2>Beendigung des Nutzungsvertrags</h2>
        <p>
          5.1 Sie haben das Recht, Ihr Nutzerkonto jederzeit zu l&ouml;schen und die Nutzung der App
          zu beenden.
          <br />
          5.2 Wir behalten uns das Recht vor, Ihr Nutzerkonto oder den Zugang zur App aus wichtigen
          Gr&uuml;nden zu sperren oder zu beenden.
          <br />
          5.3 Wir sind berechtigt, die App jederzeit zu &auml;ndern oder einzustellen.
        </p>
        <h2>Haftungsausschluss</h2>
        <p>
          6.1 Die Nutzung der App erfolgt auf eigene Gefahr. Wir &uuml;bernehmen keine Haftung
          f&uuml;r Sch&auml;den, die aus der Nutzung der App resultieren.
        </p>
        <h2>&Auml;nderungen der AGB</h2>
        <p>
          7.1 Wir behalten uns das Recht vor, diese AGB jederzeit zu &auml;ndern. &Auml;nderungen
          werden Ihnen in angemessener Weise mitgeteilt.
        </p>
        <h2>Schlussbestimmungen</h2>
        <p>
          8.1 Diese AGB unterliegen dem Schweizer Recht.
          <br />
          8.2 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, ber&uuml;hrt dies
          nicht die Wirksamkeit der &uuml;brigen Bestimmungen.
          <br />
          <br />
          Diese AGB sind ab dem 21.10.2023 g&uuml;ltig.
          <br />
          <br />
          Mentesana Klinik
          <br />
          Musterstrasse 2<br />
          6006 Luzern
        </p>
      </div>
      <div className='modal-footer'>
        <button className='waves-effect waves-green btn-flat' onClick={props.onClose}>
          Schliessen
        </button>
      </div>
    </div>
  );
});

export default AgbModal;
