import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useChat } from '../../contexts/ChatContext';
import useDbChat from '../../helpers/database/useDbChat';
import MessageModal from '../modals/MessageModal';
import format from 'date-fns/format';
import Icon from '@mdi/react';
import { mdilDelete, mdilPencil } from '@mdi/light-js';

export type MessageProps = {
  message: {
    messageId: string;
    data: {
      date: Date;
      senderId: string;
      text: string;
      img?: string;
    };
  };
  isLoadingMore: boolean;
  setIsLoadingMore: (value: boolean) => void;
};

const Message: React.FC<MessageProps> = ({ message, isLoadingMore, setIsLoadingMore }) => {
  const { user: currentUser } = useContext(UserContext);
  const {
    data: { user, chatId },
  } = useChat();
  const { editMessage, deleteMessage } = useDbChat();
  const [modalMode, setModalMode] = useState<'edit' | 'delete' | null>(null);
  const [modalInstance, setModalInstance] = useState<M.Modal | null>(null);

  // Ref von der Scrollbar
  const ref = useRef<HTMLDivElement>(null);

  //Modal Initialisierungen
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalMode && modalRef.current) {
      const instance = M.Modal.init(modalRef.current, { dismissible: false });
      setModalInstance(instance);
      instance.open();
    }
  }, [modalMode]);

  //Modal bei null schliessen
  useEffect(() => {
    if (modalMode === null && modalInstance) {
      modalInstance.close();
    }
  }, [modalMode, modalInstance]);

  // Modal Dialog
  const onModalConfirm = (value?: string) => {
    if (modalMode === 'edit' && value) {
      editMessage(chatId, message.messageId, value);
    } else if (modalMode === 'delete') {
      deleteMessage(chatId, message.messageId);
    }
    setModalMode(null); // Modal schliessen
  };

  const onModalCancel = () => {
    setModalMode(null); // Modal schliessen
  };

  //Scrollen
  useEffect(() => {
    if (isLoadingMore) {
      setTimeout(() => {
        setIsLoadingMore(false);
      }, 300);
      return;
    }
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 300); // Verzögerung von 300ms, damit es ganz nach unten scrollt
  }, [message]);

  // Nachricht bearbeiten
  const handleEditMessageClick = () => {
    setModalMode('edit');
  };

  //Nachricht löschen
  const handleDeleteMessageClick = () => {
    setModalMode('delete');
  };

  return (
    <>
      {(message.data.text || message.data.img) && (
        <div
          ref={ref}
          className={`message ${message.data.senderId === currentUser?.uid && 'owner'}`}
        >
          <div className='messageInfo'>
            <img
              src={
                message.data.senderId === currentUser?.uid ? currentUser.photoURL : user.photoURL
              }
              alt='User'
            />
            <div className='date-clock'>
              <span>{format(new Date(message.data.date), 'dd.MM.yy')}</span>
              <span>{format(new Date(message.data.date), 'HH:mm')}</span>
            </div>
            <div className='messageActions'>
              {message.data.senderId === currentUser?.uid && (
                <div>
                  <span onClick={handleEditMessageClick}>
                    <Icon path={mdilPencil} size={1} />
                  </span>
                  <span onClick={handleDeleteMessageClick}>
                    <Icon path={mdilDelete} size={1} />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='messageContent'>
            {message.data.text && <p>{message.data.text}</p>}
            {message.data.img && <img src={message.data.img} alt='Image' />}
          </div>
        </div>
      )}
      <MessageModal
        id='messageModal'
        ref={modalRef}
        mode={modalMode}
        onConfirm={onModalConfirm}
        onCancel={onModalCancel}
        defaultValue={modalMode === 'edit' ? message.data.text : undefined}
      />
    </>
  );
};

export default Message;
