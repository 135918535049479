import React, { createContext, useReducer, ReactNode, useContext } from 'react';

import { FirebaseUser, useUser } from './UserContext';

// Definieren der State- und Action-Typen
type ChatAction = ChangeUserAction | ResetUserAction;

interface ChatState {
  chatId: string;
  user: FirebaseUser;
}

interface ChangeUserAction {
  type: 'CHANGE_USER';
  payload: FirebaseUser;
}

interface ResetUserAction {
  type: 'RESET_USER';
}

// Chat Context erstellen
interface ChatContextType {
  data: ChatState;
  dispatch: React.Dispatch<ChatAction>;
}

interface ChatContextProviderProps {
  children: ReactNode;
}

export const useChat = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatContextProvider');
  }
  return context;
};

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

/* eslint-disable react/prop-types */
export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({ children }) => {
  const { user } = useUser();

  const INITIAL_STATE: ChatState = {
    chatId: 'null',
    user: {
      uid: '', // Leer, da ein UID immer vorhanden sein muss. Könnte auch durch einen Platzhalter ersetzt werden.
      displayName: '',
      photoURL: '',
    },
  };

  const chatReducer = (state: ChatState, action: ChatAction): ChatState => {
    switch (action.type) {
      case 'CHANGE_USER':
        return {
          user: action.payload,
          chatId:
            user && action.payload
              ? user.uid > action.payload.uid
                ? user.uid + action.payload.uid
                : action.payload.uid + user.uid
              : 'null',
        };
      case 'RESET_USER':
        return { ...INITIAL_STATE };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return <ChatContext.Provider value={{ data: state, dispatch }}>{children}</ChatContext.Provider>;
};
