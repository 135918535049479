import { collection, getCountFromServer, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../useFirebase';

export type Blog = {
  blogId: string;
  data: {
    title: string;
    leadtext: string;
    content: string;
    colorScheme: string;
    imageStatic: string;
    imageAnimated: string;
    createdDate: string;
  };
};

const useDbBlog = () => {
  // Hole alle Blog-Beiträge von Flamelink aus der DB
  const getBlogEntries = async (numberOfEntries: number): Promise<Blog[]> => {
    try {      
      // Hole nur Beiträge, die mit "Publish" markiert sind.
      // Sortiere diese gleich nach createdDate (letzter Beitrag zu oberst) und limitiere die Anzahl auf numberOfEntries
      const blogCollection = query(collection(db, 'fl_content'), where('_fl_meta_.status', '==', 'publish'), orderBy('_fl_meta_.createdDate', 'desc'), limit(numberOfEntries));
      const allDocs = await getDocs(blogCollection);

      const blogs: Blog[] = [];

      // Fülle die Daten für alle Einträge ab
      allDocs.forEach((doc) => {
          const data = doc.data();
            const blog: Blog = {
              blogId: doc.id,
              data: {
                title: data.title || '',
                leadtext: data.leadtext || '',
                content: data.content || '',
                colorScheme: data.colorScheme || '#ffd54f',
                imageStatic: data.imageStatic || null,
                imageAnimated: data.imageStatic
                  ? data.imageStatic.replace('static', 'animate')
                  : null,
                createdDate: data._fl_meta_.createdDate,
              },
            };
            blogs.push(blog);
      });
      
      return blogs;
    } catch (error) {
      console.error('Blog entries could not be retrieved: ', error);
      return [];
    }
  };

  // Zähle alle vorhandenen "publish" Blog-Beiträge von Flamelink aus der DB
  const countBlogEntries = async (): Promise<number> => {
    try {      
      // Hole nur Beiträge, die mit "Publish" markiert sind.
      const blogCollection = query(collection(db, 'fl_content'), where('_fl_meta_.status', '==', 'publish'));
      const allDocs = await getCountFromServer(blogCollection);
      return allDocs.data().count;
    } catch (error) {
      console.error('Blog entries could not be counted: ', error);
      return 0;
    }
  };

  return {
    getBlogEntries,
    countBlogEntries
  };
};
export default useDbBlog;
