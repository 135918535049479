import React, { forwardRef } from 'react';

interface ChatDeleteProps {
  id: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const BlogModal = forwardRef<HTMLDivElement, ChatDeleteProps>((props, ref) => {
  return (
    <div ref={ref} className='modal modal-styled modal-small'>
      <div className='modal-title'>Chat löschen</div>
      <div className='modal-content'>
        <p>Möchten Sie diesen Chat wirklich löschen?</p>
        <p>Der Chatverlauf wird sowohl bei Ihnen als auch bei Ihrem Chatpartner entfernt.</p>
      </div>
      <div className='modal-footer'>
        <button className='modal-close waves-effect waves-green btn-flat' onClick={props.onConfirm}>
          Löschen
        </button>
        <button className='modal-close waves-effect waves-red btn-flat' onClick={props.onCancel}>
          Abbrechen
        </button>
      </div>
    </div>
  );
});

export default BlogModal;
