import React from 'react';
import { useNavigate } from 'react-router-dom';
import CopingStrategyPlans from '../../components/emergency/CopingStrategyPlans';

const CopingStrategyPlan: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='page copingstrategyplan'>
      <div className='content-header'>
        <h1>Bewältigungsstrategien</h1>
        <button
          className='btn-large btn-styled waves-effect waves-light'
          onClick={() => navigate('/emergency/copingstrategydata')}
        >
          Plan erfassen
        </button>
      </div>
      <CopingStrategyPlans />
    </div>
  );
};
export default CopingStrategyPlan;
