import React, { useEffect, useRef } from 'react';

interface Props {
  isOpen: boolean;
}

const showSpinner: React.FC<Props> = ({ isOpen }) => {
  const modalRef = useRef<HTMLElement | null>(null);
  const modalInstanceRef = useRef<M.Modal | null>(null);

  // Initialisiere das Modal nur einmal
  useEffect(() => {
    modalRef.current = document.getElementById('preloaderModal');
    if (modalRef.current) {
      modalInstanceRef.current = M.Modal.init(modalRef.current);
    }
    return () => {
      modalInstanceRef.current?.destroy();
    };
  }, [isOpen]);

  // Öffne oder schliesse das Modal basierend auf isOpen
  useEffect(() => {
    if (isOpen) {
      modalInstanceRef.current?.open();
    } else {
      modalInstanceRef.current?.close();
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div id='preloaderModal' className='modal'>
          <div className='modal-content center-align'>
            <div className='preloader-wrapper active'>
              <div className='spinner-layer spinner-blue'>
                <div className='circle-clipper left'>
                  <div className='circle'></div>
                </div>
                <div className='gap-patch'>
                  <div className='circle'></div>
                </div>
                <div className='circle-clipper right'>
                  <div className='circle'></div>
                </div>
              </div>

              <div className='spinner-layer spinner-red'>
                <div className='circle-clipper left'>
                  <div className='circle'></div>
                </div>
                <div className='gap-patch'>
                  <div className='circle'></div>
                </div>
                <div className='circle-clipper right'>
                  <div className='circle'></div>
                </div>
              </div>

              <div className='spinner-layer spinner-yellow'>
                <div className='circle-clipper left'>
                  <div className='circle'></div>
                </div>
                <div className='gap-patch'>
                  <div className='circle'></div>
                </div>
                <div className='circle-clipper right'>
                  <div className='circle'></div>
                </div>
              </div>

              <div className='spinner-layer spinner-green'>
                <div className='circle-clipper left'>
                  <div className='circle'></div>
                </div>
                <div className='gap-patch'>
                  <div className='circle'></div>
                </div>
                <div className='circle-clipper right'>
                  <div className='circle'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default showSpinner;
