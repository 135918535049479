import React from 'react';
import { useUser } from '../contexts/UserContext';
import MeaVia from '../assets/Schriftzug_MeaVia.svg';
import { ReactComponent as AnimatedSvg } from '../assets/Home_OK-animate.svg';

const Home: React.FC = () => {
  const { user } = useUser();
  return (
    <div className='page'>
      <div className='homecontainer'>
        <div className='textcontainer'>
          <h3 className='titletext'>Hallo {user?.displayName}</h3>
          <h3 className='titletext'>Wie geht es dir?</h3>
        </div>

        <AnimatedSvg />

        <img src={MeaVia} alt='MeaVia' className='titlegrafic' />
      </div>
    </div>
  );
};

export default Home;
