import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import useAuth from '../../helpers/useAuth';
import { auth } from '../../helpers/useFirebase';
import useDbImage from '../../helpers/database/useDbImage';
import useDbUser from '../../helpers/database/useDbUser';
import VerificationModal from '../../components/modals/VerificationModal';
import AgbModal from '../../components/modals/AgbModal';
import Spinner from '../../components/Spinner';
import Schriftzug from '../../assets/Schriftzug_MeaVia.svg';
import DefaultAvatar from '../../assets/Default_Avatar.svg';
import Icon from '@mdi/react';
import { mdilImage } from '@mdi/light-js';

const Register: React.FC = () => {
  const [modalInstance, setModalInstance] = useState<M.Modal | null>(null);
  const [currentUserState, setCurrentUserState] = useState<User>();
  const [isSpinnerOpen, setSpinnerOpen] = useState<boolean>(false);
  const { uploadImageAvatar } = useDbImage();
  const { createUser } = useDbUser();
  const navigate = useNavigate();
  const {
    displayName,
    displayNameError,
    email,
    emailError,
    password,
    passwordError,
    avatar,
    avatarError,
    error,
    setDisplayName,
    setEmail,
    setPassword,
    setAvatar,
    setError,
    isError,
  } = useAuth();
  const [isChecked, setChecked] = useState<boolean>(false);
  const [checkboxChanged, setCheckboxChanged] = useState<boolean>(false);

  const handleDisplayNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(e.target.value);
  }, []);

  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const handleAvatarChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAvatar(e.target.files[0]);
    }
  }, []);

  const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setCheckboxChanged(true);
  }, []);

  const handleRegistration = async (e: FormEvent) => {
    e.preventDefault();

    // Versuche, Benutzer bei Firebase zu registrieren
    try {
      setSpinnerOpen(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setCurrentUserState(currentUserState);
      await sendEmailVerification(userCredential.user);
      let photoURL: string;

      if (avatar && avatarError == '') {
        photoURL = await uploadImageAvatar(avatar, userCredential.user.uid);
      } else {
        photoURL = DefaultAvatar;
      }
      await createUser(userCredential, displayName, photoURL);
      setSpinnerOpen(false);
      onVerificationModalOpen();
    } catch {
      setError(true);
      console.error('Registration failed');
      setSpinnerOpen(false);
    }
  };

  useEffect(() => {
    setCurrentUserState(currentUserState);
  }, [currentUserState]);

  // Modal Initialisierungen
  const verificationModalRef = useRef<HTMLDivElement>(null);
  const agbModalRef = useRef<HTMLDivElement>(null);

  // Gewünschtes Modal öffnen
  const onVerificationModalOpen = () => {
    if (verificationModalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(verificationModalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };
  const onAgbModalOpen = () => {
    if (agbModalRef.current && !modalInstance?.isOpen) {
      const instance = M.Modal.init(agbModalRef.current);
      setModalInstance(instance);
      instance.open();
    }
  };

  // Gewünschtes Modal schliessen
  const onVerificationModalClose = () => {
    if (modalInstance) {
      modalInstance.close();
      navigate('/login');
    }
  };
  const onAgbModalClose = () => {
    if (modalInstance) {
      modalInstance.close();
    }
  };

  return (
    <div className='register'>
      <div className='formWrapper'>
        <div className='logo'>
          <img src={Schriftzug} alt='MeaViaLogo' className='meavialogo' />
        </div>
        <div className='title'>Registrierung</div>
        <form className='form-styled'>
          <div className='input-field'>
            <input
              id='displayname-register'
              type='text'
              onChange={handleDisplayNameChange}
              onBlur={handleDisplayNameChange}
            />
            <label htmlFor='displayname-register'>Anzeigename *</label>
            {displayNameError && <span className='validation'>{displayNameError}</span>}
          </div>
          <div className='input-field'>
            <input
              id='email-register'
              type='email'
              onChange={handleEmailChange}
              onBlur={handleEmailChange}
            />
            <label htmlFor='email-register'>E-Mail *</label>
            {emailError && <span className='validation'>{emailError}</span>}
          </div>
          <div className='input-field'>
            <input
              id='password-register'
              type='password'
              onChange={handlePasswordChange}
              onBlur={handlePasswordChange}
            />
            <label htmlFor='password-register'>Passwort *</label>
            {passwordError && <span className='validation'>{passwordError}</span>}
          </div>
          <div className='file-field input-field avatar-container'>
            <div className='avatar-upload'>
              <Icon className='icon' path={mdilImage} />
              <input id='avatarimage-register' type='file' onChange={handleAvatarChange} />
            </div>
            <div className='file-path-wrapper'>
              <input
                id='avatarimage-register-path'
                className='file-path'
                type='text'
                placeholder='Avatar hochladen'
              />
            </div>
            {avatarError && <span className='validation'>{avatarError}</span>}
          </div>
          <div className='agb-container'>
            <label>
              <input type='checkbox' checked={isChecked} onChange={handleCheckboxChange} />
              <span></span>
            </label>
            <label>
              <p>
                Ich akzeptiere die <a onClick={onAgbModalOpen}> AGBs</a>. * <br />
              </p>
              {checkboxChanged && !isChecked && (
                <span className='validation'>Bitte akzeptiere die AGBs.</span>
              )}
            </label>
          </div>
          <button
            className={`btn-large btn-styled waves-effect waves-light ${
              isError() || !isChecked ? 'disabled' : ''
            }`}
            onClick={handleRegistration}
          >
            Registrieren
          </button>
          {error && <span className='validation'>Registrierung fehlgeschlagen!</span>}
        </form>
        <p>
          Bereits ein Konto vorhanden? <Link to='/login'>Login</Link>
        </p>
      </div>
      <VerificationModal
        id='VerificationModal'
        ref={verificationModalRef}
        isFirstCall={true}
        user={currentUserState}
        onClose={onVerificationModalClose}
      />
      <AgbModal id='agbModal' ref={agbModalRef} onClose={onAgbModalClose} />
      <Spinner isOpen={isSpinnerOpen} />
    </div>
  );
};

export default Register;
