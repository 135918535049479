import React, { forwardRef } from 'react';

interface NewContactModalProps {
  id: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const NewContactModal = forwardRef<HTMLDivElement, NewContactModalProps>((props, ref) => {
  return (
    <div ref={ref} id='NewContactModal' className='modal modal-styled modal-small'>
      <div className='modal-title'>Achtung Datenverlust</div>
      <div className='modal-content'>
        <p>
          Bei einem Seitenwechsel können Eingaben verloren gehen. Bitte sichere zuerst den
          Sicherheitsplan.
        </p>
        <p>Möchtest du trotzdem fortfahren?</p>
      </div>
      <div className='modal-footer'>
        <a
          href='#!'
          className='modal-close waves-effect waves-green btn-flat'
          onClick={props.onCancel}
        >
          Abbrechen
        </a>
        <a
          href='#!'
          className='modal-close waves-effect waves-green btn-flat'
          onClick={props.onConfirm}
        >
          Weiter
        </a>
      </div>
    </div>
  );
});

export default NewContactModal;
