import React, { useState } from 'react';
import SidebarChat from '../components/chat/SidebarChat';
import Chat from '../components/chat/Chat';

const Chatpage: React.FC = () => {
  const [isChatSelected, setIsChatSelected] = useState<boolean>(false);
  return (
    <div className='page chatpage'>
      <div className='container'>
        <SidebarChat isChatSelected={isChatSelected} setIsChatSelected={setIsChatSelected} />
        {isChatSelected && <Chat />}
      </div>
    </div>
  );
};

export default Chatpage;
