import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { Contact } from '../../pages/emergency/ContactData';
import { db } from '../useFirebase';
import useE2EE from '../useE2EE';

const useDbContact = () => {
  const { encryptData, decryptData } = useE2EE();

  // Erstelle für den Benutzer einen neuen Kontakt in der DB
  const createContact = async (
    currentUserUid: string,
    contactId: string,
    name: string,
    relationship?: string,
    address?: string,
    phone1?: string,
    phone2?: string,
  ): Promise<void> => {
    const baseContact: Contact = {
      contactId,
      data: {
        name,
        relationship,
        address,
        phone1,
        phone2,
      },
    };

    try {
      const contactsDoc = await getDoc(doc(db, 'contacts', currentUserUid));
      if (!contactsDoc.exists()) {
        // Erstelle Kontakt Collection für Benutzer
        await setDoc(doc(db, 'contacts', currentUserUid), { contacts: [] });
      }

      // Verschlüssle die Kontaktdaten
      const encryptedContact = await encryptData(JSON.stringify(baseContact.data));

      // Erweitere Kontakt-Array um die neuen Daten
      await updateDoc(doc(db, 'contacts', currentUserUid), {
        contacts: arrayUnion({
          contactId: baseContact.contactId,
          encryptedContact: encryptedContact,
        }),
      });
    } catch (error) {
      console.error('Contact could not be created: ', error);
    }
  };

  // Aktualisiere Kontakt von Benutzer in DB
  const updateContact = async (uid: string, updatedContact: Contact) => {
    try {
      const contactsRef = doc(db, 'contacts', uid);
      const contactDoc = await getDoc(contactsRef);

      // Überprüfung, ob Kontaktbereich vorhanden ist
      if (contactDoc.exists()) {
        const currentContacts = contactDoc.data().contacts;

        // Suche nach Kontakt
        const existingContact = await getContact(uid, updatedContact.contactId);

        if (existingContact) {
          // Index des zu aktualisierenden Kontakts finden
          const contactIndex = currentContacts.findIndex(
            (contact: { contactId: string }) => contact.contactId === updatedContact.contactId,
          );

          // Verschlüssle die aktualisierten Kontaktdaten
          const encryptedContact = await encryptData(JSON.stringify(updatedContact.data));

          // Kontakt entfernen und den aktualisierten Kontakt hinzufügen
          const newContacts = [...currentContacts];
          newContacts[contactIndex] = {
            contactId: updatedContact.contactId,
            encryptedContact: encryptedContact,
          };

          await updateDoc(contactsRef, { contacts: newContacts });
        }
      }
    } catch (error) {
      console.error('Contact could not be updated: ', error);
    }
  };

  // Suche nach einem Kontakt in der DB
  // Gibt gefundenen Kontakt entschlüsselt als Array zurück
  const getContact = async (uid: string, contactId: string): Promise<Contact | null> => {
    try {
      const contactDoc = await getDoc(doc(db, 'contacts', uid));

      // Überprüfung, ob Kontaktbereich vorhanden ist
      if (contactDoc.exists()) {
        const contactData = contactDoc.data().contacts;

        // Suche nach dem Kontakt mit der entsprechenden ID
        const foundContact = contactData.find(
          (contact: { contactId: string }) => contact.contactId === contactId,
        );
        if (foundContact) {
          // Entschlüssle Kontakt
          const decryptedContact = await decryptData(foundContact.encryptedContact);
          // getAllContacts(uid);
          return {
            contactId,
            data: JSON.parse(decryptedContact),
          };
        }
      }
      return null;
    } catch (error) {
      console.error('Contact could not be found: ', error);
      return null;
    }
  };

  // Holen aller Kontakte eines bestimmten Benutzers aus DB
  // Gibt Array mit allen Kontakten zurück
  const getAllContacts = async (uid: string): Promise<Contact[]> => {
    try {
      const contactDoc = await getDoc(doc(db, 'contacts', uid));

      // Überprüfung, ob Kontaktbereich vorhanden ist
      if (contactDoc.exists()) {
        // Hole alle enthaltenen Einträge und speichere sie in eine Array
        const data = contactDoc.data().contacts;
        const contacts = [];

        for (const d of data) {
          // Entschlüssle Kontakt
          const decryptedContact = await decryptData(d.encryptedContact);

          // Füge den Kontakt dem Array hinzu
          contacts.push({
            contactId: d.contactId,
            data: JSON.parse(decryptedContact),
          });
        }
        return contacts;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Contacts could not be retrieved: ', error);
      return [];
    }
  };

  // Suche nach der ID eines Kontakts in der DB
  const getDocumentIdByContactId = async (contactId: string): Promise<string | null> => {
    try {
      const contactsCollection = collection(db, 'contacts');
      const allDocs = await getDocs(contactsCollection);

      for (const doc of allDocs.docs) {
        const contactsArray: Contact[] = doc.data().contacts || [];
        if (contactsArray.some((contact) => contact.contactId === contactId)) {
          return doc.id;
        }
      }
      return null;
    } catch (error) {
      console.error('DocumentID could not be retrieved: ', error);
      return null;
    }
  };

  // Lösche Kontakt eines Benutzers aus der DB
  const deleteContact = async (contactId: string) => {
    try {
      const docId = await getDocumentIdByContactId(contactId);

      if (!docId) {
        throw new Error('Document with contact could not be found.');
      }

      const contactDoc = doc(db, 'contacts', docId);
      const contactData = (await getDoc(contactDoc)).data();

      if (!contactData || !contactData.contacts) {
        throw new Error('Invalid data structure in document.');
      }

      const updatedContacts = contactData.contacts.filter(
        (contact: Contact) => contact.contactId !== contactId,
      );

      await updateDoc(contactDoc, {
        contacts: updatedContacts,
      });
    } catch (error) {
      console.error('Contact could not be deleted: ', error);
    }
  };

  return {
    createContact,
    updateContact,
    getContact,
    getAllContacts,
    getDocumentIdByContactId,
    deleteContact,
  };
};

export default useDbContact;
